import { useNavigate} from "react-router-dom";
import  './Backtitle.css'
function BackSubTitle({link, title,menu,submenuName,state}) {
  const navigate = useNavigate();
  return (
    <div className="backTitle" onClick={() => navigate(link,{state:{activemenu:menu,submenu:submenuName}})}>
      <span style={{ marginLeft: "10px" ,fontFamily: "Open Sans",}}>{title}</span>
    </div>
  );
}
export default BackSubTitle;


