import React,{useState} from "react";
// import EArchiture from "../../images/ARCHITECTURE.png"
import EArchiture from "../../images/MoveAmericaARCHITECTURE.png"
import Sidebar from "../../components/SideNavBar/SideNavBar";
import arrow from '../../images/alert_creation_arrow.png';
import {ReactComponent as ChatBotLogo} from "../../images/ChatBotButton.svg";
import axios from 'axios';
import ChatBot from "../../templates/ChatBot";
import { Provider} from "react-redux";
import store from '../../store';
import './Artifact.css';
import TopBar from "../../components/TopBar/TopBar";
const DocumentLoaderWindow = () => {
  const [isChatBotOpen, setChatBotOpen] = useState(false);
  const HnadleChatBotClicked = async(e) => {
     
      let jiraCreationUrl="https://sdvexperience-backend.deloitte.com/sdv/upload_pdf/"
              let requestBody={
                "question":"inputText"
            };
          //API Calling
          await axios.post(jiraCreationUrl,requestBody
        ).then(result => {
            console.log(result);
            setChatBotOpen(true);   
          
        }).catch(error => { 
    
        })  
        
       
  };
  const handleClosePopup = () => {
      setChatBotOpen(false);
  };
    const YourContentComponent = () => {
      return (
        <Provider store={store}>
        <div id="app" className="background">
        <Sidebar />
          <div class="container">
            <div class="contentContainer">
            
              <TopBar></TopBar>
        <main className="main">
                <div className="mainWindowGap">
                <div className="chapterTitle">Artifacts</div>
          <img src={arrow} className="arrowStyle" alt=""/>
          <div className="subTitle">E/E Architecture</div>
          </div>
          <div className="artifactTitle"> 
          E/E Architecture
          </div>
          <div className="imageLoader">          
          <img src={EArchiture}  className="archImage" alt="" />   
          </div>
          <ChatBotLogo className="bottomRightElement" onClick={HnadleChatBotClicked}></ChatBotLogo>   
              </main>
            </div>
          </div>
          {isChatBotOpen && (
        <div className="chatBotLogo">
          <ChatBot onCloseClick={handleClosePopup} />
        </div>
      )}
        </div>
        </Provider>
      );
      };
        return (
          <YourContentComponent />                     
        );    
};
export default DocumentLoaderWindow;
