import React, { useEffect, useState } from "react";
import { Input } from "antd";
import DeleteIcon from "../../../src/images/DeleteOutlined.svg";
const { TextArea } = Input;

const TextAreaCutomComponent = ({
  onChange,
  Title,
  onDelete,
  value,
  id,
  deleteVisibility,
}) => {
  return (
    <div
      style={{
        height: "150px",
        
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom:"0.5em",
          alignItems: "flex-start",
        }}
      >
        <label className="inputHeadingText">{Title}</label>
        {deleteVisibility && (
          <img
            src={DeleteIcon}
            alt=""
            onClick={onDelete}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <TextArea
        // showCount
        onChange={onChange}
        placeholder=""
        style={{
          height: "76px",
          resize: "none",
          border: "1px solid #8C8C8C",
          backgroundColor: "#000",
          color: "white",
        }}
        value={value}
      />
    </div>
  );
};
export default TextAreaCutomComponent;
