import React, { useState } from "react";
import "./Tab.css";

const TabSelectComponent = ({
  setCurrentTab,
  firstTabTitle,
  secondTabTitle,
  thirdTabTitle,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (tabNumber) => {
    setSelectedTab(tabNumber);
    setCurrentTab(tabNumber);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <div
          className={`tab ${selectedTab === 0 ? "selected" : ""}`}
          onClick={() => handleTabClick(0)}
        >
          {firstTabTitle}
        </div>
        <div
          className={`tab ${selectedTab === 1 ? "selected" : ""}`}
          onClick={() => handleTabClick(1)}
        >
          {secondTabTitle}
        </div>
        <div
          className={`tab ${selectedTab === 2 ? "selected" : ""}`}
          onClick={() => handleTabClick(2)}
        >
          {thirdTabTitle}
        </div>
      </div>
    </div>
  );
};

export default TabSelectComponent;
