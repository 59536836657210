import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  // TODO: Use authentication token
  const localStorageToken = localStorage.getItem("token");

  return localStorageToken ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
