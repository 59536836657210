import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import "./CustomPopup.css";

const CustomPopup = (props) => {
  const [show, setShow] = useState(false);

  const closeHandler = (e) => {
    setShow(false);
    localStorage.removeItem("tasksLength");
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className="overlay"
    >
      <div className="popup" style={{ width: props.width }}>
        <h2 className="popupTitle titleContainer">{props.title}</h2>
        <span
          className="close"
          onClick={closeHandler}
        >
          &times;
        </span>
        <div className={props.scroll ? "scroll_content" : "content"}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

CustomPopup.propTypes = {
  width: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scroll: PropTypes.bool,
};
export default CustomPopup;
