export const linkStyle = {
  textDecoration: "none",
};

export const linkStyleWhite = {
  textDecoration: "none",
  color: "white",
};

export const linkStyleGreen= {
  textDecoration: "none",
  color: "#92BB45",
};

export const arcStyle = {
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1,
};

// export const TopBarHeight = '80px';
export const TopBarHeight = '60px';

export const sidebarStyle = {
  position: 'fixed',
  top: TopBarHeight,
  left: 0,
  zIndex: 100
}

export const snackbar = {
  position: 'absolute',
  top: '5rem',
    color: 'white',
  
  backgroundColor: '#de8500',
  padding: '1rem',
  width: '500px',
  textAlign: 'center',
  fontSize: '1.25rem',
  opacity: '0.9',
}