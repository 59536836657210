import React,{useState} from "react";
import SDVARCH from "../../images/sdv_framework_ppt.png";
import Sidebar from "../../components/SideNavBar/SideNavBar";
import arrow from '../../images/alert_creation_arrow.png';
import {ReactComponent as ChatBotLogo} from "../../images/ChatBotButton.svg";
import ChatBot from "../../templates/ChatBot";
import './Artifact.css';
import TopBar from "../../components/TopBar/TopBar";

const DocumentLoaderWindowSDV = () => {  
  const [isChatBotOpen, setChatBotOpen] = useState(false);
    const HnadleChatBotClicked = async(e) => {
      setChatBotOpen(true);  
    };
    const handleClosePopup = () => {
      setChatBotOpen(false);
  };
    const YourContentComponent = () => {
      return (
        <div id="app" className="background">
            <Sidebar />
          <div className="container">
            <div className="contentContainer">
           
              <TopBar></TopBar>
              <main className="main">
                <div className="mainWindowGap" >
         <div className="chapterTitle">Artifacts</div>
         <img src={arrow} className="arrowStyle" alt=""/>
          <div className="subTitle">SDV Framework</div>
          </div>
          <div className="artifactTitle"> 
            SDV Framework
          </div>
          <div className="imageLoader" >          
          <img src={SDVARCH}  className="archImage" alt="" />   
          </div>
            <ChatBotLogo className="bottomRightElement" onClick={HnadleChatBotClicked}></ChatBotLogo> 
          </main>
            </div>
          </div>
          {isChatBotOpen && (
        <div className="chatBotLogo">
          <ChatBot onCloseClick={handleClosePopup} />
        </div>
      )}
        </div>
      );
    };
      return (
        <YourContentComponent />
      );
};
export default DocumentLoaderWindowSDV;
