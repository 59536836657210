import React, { useState ,useEffect} from 'react';
import './Popup.css';
import axios from 'axios';
import { CircularProgress} from "@mui/material";
const Popup = ({ onNoClick,issue,vin}) => {
  
  const currentDate = new Date().toLocaleDateString();
   const [responseText, setResponseText] = useState("The driver distraction application is incorrectly detecting distractions in the cluster, such as 'Hair & Makeup' alerts even when the driver is driving safely.\n\nRoot cause analysis: The root cause ofthis issue lies in the algorithm used by the driver distraction application. It seems that the algorithm is not accurately differentiating between actual distractions and normal driving behavior. This could be due to a flaw in the programming logic or a lack of sufficient training data for the algorithm.\n\nDetailed description: The driver distraction application is an important feature that helps ensure the safety of the driver by alerting them to potential distractions. However, in this case, the application is providing incorrect detections in the instrument cluster. Even when the driver is driving safely, the application displays distractions like 'Hair & Makeup' alerts, which are clearly false positives.\n\nTo address this issue, a thorough analysis of the algorithm used by the driver distraction application is required. This analysis should focus on identifying any flaws in the programming logic that may be causing the incorrect detections. Additionally, it is important to evaluate the training data used to train the algorithm. If the algorithm has not been trained on a diverse range of driving scenarios, it may not be able to accurately differentiate between distractions and normal driving behavior.\n\nOnce the root cause of the issue is identified, appropriate measures can be taken to rectify it. This may involve updating the algorithm with improved logic or providing additional training data to enhance its accuracy. It is crucial to ensure that the driver distraction application functions correctly to provide reliable alerts and contribute to the overall safety of the vehicle."
   );
  //  setResponseText("The driver distraction application is incorrectly detecting distractions in the cluster, such as 'Hair & Makeup' alerts even when the driver is driving safely.\n\nRoot cause analysis: The root cause ofthis issue lies in the algorithm used by the driver distraction application. It seems that the algorithm is not accurately differentiating between actual distractions and normal driving behavior. This could be due to a flaw in the programming logic or a lack of sufficient training data for the algorithm.\n\nDetailed description: The driver distraction application is an important feature that helps ensure the safety of the driver by alerting them to potential distractions. However, in this case, the application is providing incorrect detections in the instrument cluster. Even when the driver is driving safely, the application displays distractions like 'Hair & Makeup' alerts, which are clearly false positives.\n\nTo address this issue, a thorough analysis of the algorithm used by the driver distraction application is required. This analysis should focus on identifying any flaws in the programming logic that may be causing the incorrect detections. Additionally, it is important to evaluate the training data used to train the algorithm. If the algorithm has not been trained on a diverse range of driving scenarios, it may not be able to accurately differentiate between distractions and normal driving behavior.\n\nOnce the root cause of the issue is identified, appropriate measures can be taken to rectify it. This may involve updating the algorithm with improved logic or providing additional training data to enhance its accuracy. It is crucial to ensure that the driver distraction application functions correctly to provide reliable alerts and contribute to the overall safety of the vehicle.")

  const [summary, setSummary] = useState([]);
  const [summaryStatus, setSummaryStatus] = useState(false);

const generateSummary = async () => {

  let jiraCreationUrl =
    // "https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/sdv/generateJiraTicket/";
      "https://sdvexperience-backend.deloitte.com/sdv/generateJiraTicket/";
 
  let requestBody = {
    "vin":issue
  };
  //API Calling
await axios
    .post(jiraCreationUrl, requestBody,
      {
        withCredentials: true,
      }
    )
    
    
    .then((result) => {

let wholeSummary = result.data.split("Short Summary:");
let issueSummaryText = wholeSummary[1];

let formattedItemDesc = ""

if (issueSummaryText !== undefined) {
  formattedItemDesc += `${issueSummaryText}\n\n`;
  formattedItemDesc +=`\nGiven the claims over the last 12 months, there are two DMS boards in ciruclation. It would be helpful to evaluate the impact of the OTA package that was issued on feb 1 2024. It is a known each board has different device drivers.`
}
else{
let wholeSummary = result.data.split(/"Short Summary":/);
let issueSummaryText = wholeSummary[1];
if (issueSummaryText !== undefined) {

  formattedItemDesc +=issueSummaryText
  formattedItemDesc +=`\nGiven the claims over the last 12 months, there are two DMS boards in ciruclation. It would be helpful to evaluate the impact of the OTA package that was issued on feb 1 2024. It is a known each board has different device drivers.`
}
else{
formattedItemDesc +=responseText}
}
      setSummaryStatus(true);
      setSummary(formattedItemDesc);
    })
    .catch((error) => {});
};

const handleYesClick = async () => {
  let jiraCreationUrl =
    "https://sdvexperience-backend.deloitte.com/sdv/createJira/";
  let requestBody = {
    "vin": vin,
    "summary": summary,
    "title":issue
  };
  //API Calling
  await axios
    .post(jiraCreationUrl, requestBody)
    .then((result) => {
      onNoClick()
    })
    .catch((error) => {});
};
useEffect(() => {
  generateSummary()
}, [summaryStatus]);

return (
  <div className="issuePopup">
    <div className="popup-content">
    <div className='popup-title'>
        Jira Ticket Creation
    </div>
      <div className='issue-description'>
      <label className='lable'>Problem Summary:</label>
        {summaryStatus === true ?
        <pre >
        {summary}
      </pre>
      :
      <div className='circular-progress'>
      <CircularProgress />
    </div>
        }
        <label className='date-label'>Problem Date:</label>
      <p style={{'textAlign':'left' }}>{currentDate}</p>
        </div>
        <div className='llm-summary'>
            <p >LLM Generated Summary.Powered by GenAI.</p>
          </div>
          <div className="bottomButtons">
      <button  className='yesButton' onClick={handleYesClick}>Create Jira Ticket</button>
      <button className='closeButton' onClick={onNoClick}>Close</button>
      </div>
    </div>
  </div>
);
};

export default Popup;
