import React, { useState,useEffect} from 'react';
import './chatbot.css';
import {ReactComponent as ChatBotClose} from "../images/ChatBotClose.svg"
import {ReactComponent as MdSend} from "../images/AskQuestionSendButton.svg"
import {ReactComponent as ChatMan} from "../images/ChatPerson.svg"
import  ReactScrollableFeed from 'react-scrollable-feed'
import axios from 'axios';
const ChatBotQuality =({onCloseClick}) =>{
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [inputText, setInputText] = useState(""); // Add this line
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [embeddingsGenerated, setembeddingsStatus] = useState(false);
  const [specificChat,setSpecificChat]=useState(false);
  const [homeSelectedQuestion, setHomeSelectedQuestion] = useState([]);
  const [homeSelectedOptions, setHomeSelectedOptions] = useState([]);

const handleBackMenu=() =>{
  setSpecificChat(false)
}

const handleUserMessage = (e) => {
    setInputText(e.target.value);
}
const ClearUserMessage = () => {
  setInputText(" ");
}
const handleInputMessageChnaged= async(question)=>{
  setSelectedQuestion([]);
  setSelectedOptions([]);
  setSpecificChat(true)
  setSelectedQuestion([]);
  setSelectedOptions([]);
   //Add question in list
   setHomeSelectedQuestion([...homeSelectedQuestion, question]);
   let jiraCreationUrl="https://sdvexperience-backend.deloitte.com/sdv/ask_question/"

      let questionforEmbedd=question
       let requestBody={
           "question":questionforEmbedd
       };

     //API Calling
     await axios.post(jiraCreationUrl,requestBody
   ).then(result => {
    setHomeSelectedOptions([...homeSelectedOptions, result.data]);
       setInputText(" ");          
   }).catch(error => { 
   })    

}

const inputOption = async(e) => {
    //Add question in list
    if(specificChat===true) {
      setHomeSelectedQuestion([...homeSelectedQuestion, inputText]);
     }
     else{
       setSelectedQuestion([...selectedQuestion, inputText])
     }

    let jiraCreationUrl="https://sdvexperience-backend.deloitte.com/sdv/ask_question/"
        
        let requestBody={
            "question":inputText
        };
      //API Calling
      await axios.post(jiraCreationUrl,requestBody
    ).then(result => {
       
        if(specificChat===true) {
          setHomeSelectedOptions([...homeSelectedOptions, result.data]); 
         }
         else{
          setSelectedOptions([...selectedOptions, result.data]);   
         }
    }).catch(error => { 
    })    
}

  useEffect(() => {
  HnadleChatBotClicked()
  }, [embeddingsGenerated]);

  const HnadleChatBotClicked = async(e) => {
    let jiraCreationUrl="https://sdvexperience-backend.deloitte.com/sdv/upload_pdf/"
            let requestBody={
              "question":"inputText"
          };
        //API Calling
        await axios.post(jiraCreationUrl,requestBody
      ).then(result => {
         setembeddingsStatus(true);
      }).catch(error => { 
  
      })  
     
  };
return(
  <div className="Cpopup">
   
  <div className='Cpopup-title'>
    <div style={{fontFamily: "Open Sans",fontSize:"12px",fontWeight:"600"}}>Chat bot</div>
    <ChatBotClose  onClick={onCloseClick}></ChatBotClose>
  </div>
{embeddingsGenerated===true?
<>
  {specificChat===false?
 
  <ReactScrollableFeed>
  <div className="chat-window">
        <div className='chat-background'>
        <ChatMan className='chatMan'></ChatMan>
        <div className='chatPrimaryContect'>
        <div className='chatFixedContentOne'>Hello there! 👋 It's nice to meet you!</div>
        <div className='chatFixedContentTwo'>Feel free to utilize the navigation provided below, or simply ask any questions regarding the specifics of the alert.
        </div>
        </div>
        </div>

        <div className='chatListGap'>
        <div className='option' onClick={()=>handleInputMessageChnaged("Which region has highest number of claims")}>Which region has highest number of claims?</div>
        <div className='optionsGap'>
        <div className='option2' onClick={()=>handleInputMessageChnaged("What do you think are possible root causes for the DMS customer claims?")}>DMS customer claims root causes</div>
        </div>
       </div>
        {selectedOptions.map((question, index) => (
          <div key={index}>
          <div key={index} className='question' >{question}</div>
          <div className='answer'>{selectedOptions[index]}</div>
          </div>
        ))}

      </div>
      </ReactScrollableFeed>
      :
      <ReactScrollableFeed>

      <div className="chat-window">
            
            {homeSelectedQuestion.map((question, index) => (
              <div key={index}>
              <div  key={index} className='question'>{question}</div>
              <div className='answer'>{homeSelectedOptions[index]}</div>
              </div>
            ))}
          </div>
          <div className='backmenue'>
          <button className='backButton' onClick={()=>handleBackMenu()}>Return to Main Menue</button>
          </div>
    </ReactScrollableFeed>
 }
    <div className='question_submit'>
        <input className='embeddings_input_div'
          type="text" 
          placeholder="Ask a question"
          value={inputText} // Bind the value to the state variable
          onChange={handleUserMessage} // Update the state variable on input change
          onMouseDown={ClearUserMessage}
        />
        <MdSend style={{alignItems:"center", bcolor: "white"}} onClick={inputOption}/>
      </div>
      </>:
      <></>
}
</div>
);
  
};
export default ChatBotQuality;