import { SET_GLOBAL_DATA } from './action';

const initialState = {
  globalData: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_GLOBAL_DATA:
        return {
          ...state,
          globalData: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;