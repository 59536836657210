import "./App.css";
import React, { useState } from "react";
import { ReactComponent as LandingCar } from "./images/landing_car.svg";
import { ReactComponent as LandingPageLine } from "./images/landing_page_line.svg";
import { ReactComponent as AuthPrivacy } from "./images/AuthPrivacy.svg";
import { ReactComponent as AuthLine } from "./images/Authline.svg";
import DeloitteImg from "./images/Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import { Button } from "antd";
function App() {
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignInPOPUp, setShowSignInPOP] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  function handleSignInClick() {
    setShowSignInPOP(false);
    // setShowLoginForm(true);
    // //sso implementation
    const incidentNumber="WO0010020"
    console.log("Inside SSO Authentication");
    // window.location.href ="https://127.0.0.1:8000/login/azuread-oauth2/";
    window.location.href="https://sdvexperience-backend.deloitte.com/login/azuread-oauth2/";
    // navigate("/persona/chapter-one", {
    //   state: { activemenu: "ch1", submenu: "" },
    // });
  }
  
  // const handleLoginAuthentication = async () => {

  //   localStorage.removeItem("token");
  //   // Clear username
  //   localStorage.removeItem("username");

  //   navigate("/chapter-one", {
  //     state: { activemenu: "ch1", submenu: "" },
  //   });

  //   await axios
  //     .post(
  //       "http://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com" +
  //         "/api/user/login/",
  //       {
  //         email: email,
  //         password: password,
  //       }
  //     )
  //     .then((result) => {
  //       navigate("/chapter-one", {
  //         state: { activemenu: "ch1", submenu: "" },
  //       });
  //       localStorage.setItem("token", JSON.stringify(result.data.token.access));
  //       localStorage.setItem("username", JSON.stringify(result.data.username));
  //     })

  //     .catch((error) => {
  //       console.log("Error In Authentication");
  //       setAlertOpen(true);
  //     });
  // };
  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email state with user input
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value); // Update email state with user input
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  // Function to create a new incident
const createIncidentss = async () => {
  console.log("Inside the createIncident")
  // Replace with your actual ServiceNow instance URL and credentials
  // const url = "https://instance.servicenow.com/api/now/table/incident";
  const url = "https://deloitteus14.service-now.com/api/now/table/incident";
  
  const username = "pedsdvIntegrationUser";
  const password = "ZcoJ-F):PNl23G+#HL5ZAcLn:R0T9<{pQj:&huV9";
  
  // const username = "parpahwa@deloitte.com";
  // const password = "Apple@9497";
  
  // Data to be sent in the request body
  const data = {
      short_description: "Unable to connect to office wifi",
      assignment_group: "287ebd7da9fe198100f92cc8d1d2154e",
      urgency: "2",
      impact: "2"
  };

  try {
      // Making the POST request
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + btoa(username + ':' + password)
          },
          body: JSON.stringify(data),
          credentials:'include'
      });

      // Checking if the request was successful
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Parse the JSON response
      const jsonResponse = await response.json();
      console.log("Incident created successfully:", jsonResponse);

  } catch (error) {
      console.error("Error creating incident:", error);
  }
};

const createIncidents = async () => {
 
  const url = "https://deloitteus14.service-now.com/api/now/table/incident";
  // const username = "parpahwa@deloitte.com";
  // const password = "Apple@9497";
  const username = "pedsdvIntegrationUser";
  const password = "ZcoJ-F):PNl23G+#HL5ZAcLn:R0T9<{pQj:&huV9";
  

  const data = {
      short_description: "Unable to connect to office wifi",
      assignment_group: "287ebd7da9fe198100f92cc8d1d2154e",
      urgency: "2",
      impact: "2"
  };

  try {
      const response = await axios.post(url, data, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          auth: {
              username: username,
              password: password
          }
      });

      console.log("Incident created successfully:", response.data);
  } catch (error) {
      console.error("Error creating incident:", error);
  }
};

const createIncident = async () => {
 
  const username = "pedsdvIntegrationUser";
  const password = "ZcoJ-F):PNl23G+#HL5ZAcLn:R0T9<{pQj:&huV9";
  const credentials = btoa(`${username}:${password}`);
  
  const incidentData = {
    short_description: 'Issue with React frontend',
    description: 'User reports an issue with the application frontend',
    urgency: '2', // example field, adjust as needed
    impact: '2' // example field, adjust as needed
  };

  try {
    const response = await fetch('https://deloitteus14.service-now.com/api/now/table/incident', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`, // Basic Auth
      },
      body: JSON.stringify(incidentData)
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      // setIncidentNumber(jsonResponse.result.number); // Assuming the response returns the incident number
      console.log('Incident created:', jsonResponse);
    } else {
      console.error('Error creating incident:', response.statusText);
    }
  } catch (error) {
    console.error('Error creating incident:', error);
  }
};
  return (
    <div className="App">
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"error"} color="error">
          Login Unsuccessful. Please try again
        </Alert>
      </Snackbar>
    {/* <button style={{
                    position: 'absolute',  // Positioning at the top-left
                    top: '20px',           // Distance from the top of the page
                    left: '20px',          // Distance from the left of the page
                    backgroundColor: '#007bff', // Button color
                    color: 'white',        // Text color
                    padding: '10px 20px',  // Padding inside the button
                    border: 'none',        // Remove default border
                    borderRadius: '5px',   // Rounded corners
                    cursor: 'pointer'      // Cursor changes to pointer on hover
                }} onClick={createIncident}>createIncident</button> */}
      <LandingPageLine className="Bar" />
      <LandingCar className="Car" />
     
      {showSignInPOPUp && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            top: 0,
            right: "27vh",
          }}
        >
          <div className="SignPopUp">
            <div style={{ marginLeft: "-15px" }}>
              <img src={DeloitteImg} alt="My Image" />
            </div>
            <div className="sdv_exp">SDV EXPERIENCE</div>
            <div className="sdv_exp_subtext">
              Driving into the Future: A ‘Car to Cloud’ Journey with Software
              Defined Vehicles
            </div>
            <button className="signin-button" onClick={handleSignInClick}>
              Sign In <span className="icon">&gt;</span>
            </button>
          </div>
        </div>
      )}
      {/* {showLoginForm && (
        <div className="AuthPage">
          <div className="AuthPage_title">
            Sign in with your Deloitte account
          </div>
          <AuthLine className="AuthLine" />
          <div className="Auth_txt_one">
            By accessing this site, you confirm that this computer complies with
            your organization’s security policy.
          </div>
          <div className="input_lable">User Account</div>

          <form onSubmit={handleLoginAuthentication}>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="Auth_input"
              placeholder="XXX@example.com"
            />
            <div className="input_lable"> Password</div>
            <div>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="Auth_input"
                placeholder=""
              />
            </div>
          </form>
          <button
            className="auth-button"
            onClick={handleLoginAuthentication}
            style={{ "margin-top": "20px" }}
          >
            Sign In
          </button>

          <AuthPrivacy className="auth_privacy"></AuthPrivacy>
        </div>
      )} */}
    </div>
  );
}

export default App;
