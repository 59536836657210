import { createContext, useContext, useState } from "react";
const LoginContext = createContext();
export default function MyContextProvider({ children }) {
  const [showNotification, setShowNotification] = useState(false);
  return (
    <LoginContext.Provider value={{ showNotification, setShowNotification }}>
      {children}  
    </LoginContext.Provider>
  );
}
export function useMyContext() {
  return useContext(LoginContext);
}
