import React, { useEffect, useState, useRef } from "react";
import "./GenerateCode.css";
import InputCustomComponent from "../../../../components/CustomInput/InputCustomComponent";
import TabSelectComponent from "../../../../components/Tab/tabcomponent";
import copy from "../../../../images/copy.svg";
import download from "../../../../images/download.svg";
import info from "../../../../images/infoCircle.svg";
import ReactMarkdown from "react-markdown";
import emptyCodeStage from "../../../../images/emptyCodeStage.svg";
// import emptyCodeStage from "../../../../images/modified_highlighted_dots.svg";
import failureStage from "../../../../images/API_fail_state.svg";
import { Typography, Snackbar } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Style } from "@mui/icons-material";
import { ReactComponent as EmptyCodeStage1 } from "../../../../images/emptyCodeStage.svg"
import "./Dots.css";

const GeneratedOutputWindow = ({
  taskLength,
  outputCode,
  driverCodeResponse,
  driverResponseStatus,
  taskCodeStatus,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isRefineButtonClicked, setIsRefineButtonClicked] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [mainCodeClicked, setMainCodeClicked] = useState(false);
  const [selectedTaskId, setSelectedData] = useState(0);
  const [sideBorderColor, setSideBorderColor] = useState("");
  const [sideBorderColorForTask, setSideBorderColorForTask] = useState(""); // Store the calculated color
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterForRefineCode, setOpenToasterForRefineCode] =
    useState(false);
  const [refineCodeInput, setRefineInput] = useState("");
  const [driverCode, setDriverCode] = useState();
  const [driverCodeStatus, setDriverResponseStatus] = useState("Pending");
  const outputContentRef = useRef(null);

  useEffect(() => {
    setDriverCode(driverCodeResponse);
    setDriverResponseStatus(driverResponseStatus);
  }, [driverCodeResponse, driverResponseStatus]);
  const handleSnackbarClose = () => {
    setOpenToaster(false);
    setOpenToasterForRefineCode(false);
  };

  const copyClickHandler = () => {

    if (mainCodeClicked) {
      if (currentTab === 0) {
        return driverCode[1]?.content;
      } else if (currentTab === 1) {
        return driverCode[0]?.content;
      } else if (currentTab === 2) {
        return driverCode[2]?.content;
      }
    } else {
      return outputCode.find((data) => data.id === selectedTaskId)?.code;
    }
  };

  useEffect(() => {
    setSelectedData(outputCode[0]?.id);
    if (mainCodeClicked) {
      setSelectedData(-1);
    }
  }, [taskCodeStatus]);

  const downloadFile = (fileName, content) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  // Function to handle download button click
  const handleDownloadClick = () => {

    const zip = new JSZip();

    driverCode?.forEach((file) => {
      const { file_name, content } = file;
      zip.file(file_name, content);
    });
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, "driver_code_files.zip");
    });
  };

  const parseToMarkDown = (str) => {
    return str?.replace(/\n/g, "\n\n");
  };
  const otherCopy = () => setOpenToaster(true);
  useEffect(() => {
    const determineBorderColor = () => {
      if (driverCodeStatus === "Pending") {
        return "#FADB14";
      } else if (driverCodeStatus === "Success") {
        return "#237804";
      } else if (driverCodeStatus === "Failure") {
        return "#CF1322";
      } else {
        return "#FA8C16";
      }
    };

    const determineBorderForTaskColor = () => {
      if (taskCodeStatus === "Pending") {
        return "#FADB14";
      } else if (taskCodeStatus === "Success") {
        return "#237804";
      } else if (taskCodeStatus === "Failure") {
        return "#CF1322";
      } else {
        return "#FA8C16";
      }
    };
    setSideBorderColorForTask(determineBorderForTaskColor);
    setSideBorderColor(determineBorderColor()); // Update border color only when driverCodeStatus changes
  }, [driverCodeStatus, taskCodeStatus]); // Dependency array ensures useEffect runs when driverCodeStatus changes

  const refineButtonClickHandler = () => {
    setIsRefineButtonClicked(!isRefineButtonClicked);
    setRefineInput("");
  };

  const getRefineCode = async () => {
    setDriverResponseStatus("Pending");
    const url =

    "https://sdvexperience-backend.deloitte.com/spec/get_user_based_refined_code/"
    // "https://telematics-backend-895771443.us-east-1.elb.amazonaws.com/spec/get_user_based_refined_code/"
    
      // "http://spectocode-backend-1097162656.us-east-1.elb.amazonaws.com/get_user_based_refined_code/";

    const data = {
      user_remark: refineCodeInput,
    };
    try {
      const result = await axios.post(url, data);
      setDriverCode(result.data["response"]); // Update using the setter function
      setDriverResponseStatus("Success"); // Update using the setter function
      setIsRefineButtonClicked(false);
      setOpenToasterForRefineCode(true);
      setIsSubmitClicked(false);
      setCurrentTab(0);
    } catch (error) {
      setIsSubmitClicked(false);
      setDriverResponseStatus("Failure");
      return null;
    }
  };
  const SubmitRefinementClicked = () => {
    setIsSubmitClicked(true);
    getRefineCode();
    setRefineInput("");
    setDriverResponseStatus("Pending");
  };

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
    if (outputContentRef.current) {
      outputContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const getSelectedCodeContent = () => {
    if (currentTab === 0) {
      return parseToMarkDown(driverCode[1]?.content);
    } else if (currentTab === 1) {
      return parseToMarkDown(driverCode[0]?.content);
    } else if (currentTab === 2) {
      return parseToMarkDown(driverCode[2]?.content);
    } else {
      return "";
    }
  };

  const taskNameDisplay = () => {
    const tasks = [];
    for (let i = 0; i < taskLength; i++) {
      const isSelected = i === selectedTaskId;
      tasks.push(
        <p
          key={i} // Add a unique key for each element (important for performance)
          className="taskListStyle"
          style={{
            color: isSelected ? "#92BB45" : "white",
            border: "none",
            backgroundColor: isSelected ? "#373C32" : "#282828",
            borderLeft: `0.1875em solid ${sideBorderColorForTask}`,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedData(i);
            setMainCodeClicked(false);
          }}
        >
          {`Task ${i + 1}`}
        </p>
      );
    }
    return tasks;
  };

  return (
    <div className="generateTaskContaier">
      <p className="requirementSummaryText">Output Window</p>
      <div style={{overflowY:"auto"}}>
      <div className="flexDirectionRow">
        <div className="taskListContainer">
          <p
            className="taskListStyle"
            style={{
              color: mainCodeClicked ? "#92BB45" : "white",
              backgroundColor: mainCodeClicked ? "#373C32" : "#282828",
              borderLeft: `0.1875em solid ${sideBorderColor}`,
              marginTop: "0em",
            }}
            onClick={() => {
              setMainCodeClicked(true);
              setSelectedData(-1);
              // //Todo here we can add below function instead of calling
              // generateMainDriverCode();
            }}
          >
            Main Code
          </p>
          {outputCode.length > 0
            ? outputCode?.map((data, index) => {
                const isSelected = data.id === selectedTaskId;
                return (
                  <p
                    className="taskListStyle"
                    style={{
                      color: isSelected ? "#92BB45" : "white",
                      border: "none",
                      backgroundColor: isSelected ? "#373C32" : "#282828",
                      borderLeft: `0.1875em solid ${sideBorderColorForTask}`,
                    }}
                    onClick={() => {
                      setSelectedData(data.id);
                      setMainCodeClicked(false);
                    }}
                  >
                    {`Task ${index + 1}`}
                  </p>
                );
              })
            : taskNameDisplay()}
        </div>
        <div className="flexDirectionColumn" style={{ width: "80%" }}>
          {mainCodeClicked && (
            <TabSelectComponent
              currentTab={currentTab}
              setCurrentTab={handleTabChange}
              firstTabTitle={"Driver File"}
              secondTabTitle={"Header File"}
              thirdTabTitle={"Main Function"}
            />
          )}
          {mainCodeClicked ? (
            <p
              ref={outputContentRef}
              className="outputContent"
              style={{
                display: driverCodeStatus !== "Success" ? "flex" : "",
                alignItems: driverCodeStatus !== "Success" ? "center" : "",
                justifyContent: driverCodeStatus !== "Success" ? "center" : "",
              }}
            >
              {driverCodeStatus === "Success" ? (
                <ReactMarkdown>{getSelectedCodeContent()}</ReactMarkdown>
              ) : (
                <img
                  src={
                    driverCodeStatus === "Pending"
                      ? emptyCodeStage
                      : failureStage
                  }
                  alt=""
                />
              )}
            </p>
          ) : (
            <>
              <p className="taskTitle">
                {outputCode.find((data) => data.id === selectedTaskId)?.title}
              </p>
              <p
                className="taskTitle"
                style={{
                  fontWeight: "400",
                  marginTop: "-0.5em",
                }}
              >
                {
                  outputCode.find((data) => data.id === selectedTaskId)
                    ?.description
                }
              </p>
              <p className="taskTitle">Code Generated by Gen AI:</p>
              <p
                className="outputContent"
                style={{
                  height: "21.875em",
                  display: taskCodeStatus !== "Success" ? "flex" : "",
                  alignItems: taskCodeStatus !== "Success" ? "center" : "",
                  justifyContent: taskCodeStatus !== "Success" ? "center" : "",
                  marginTop: "-0.5em",
                }}
              >
                {selectedTaskId && taskCodeStatus === "Success" ? (
                  <ReactMarkdown>
                    {parseToMarkDown(
                      outputCode?.find((data) => data.id === selectedTaskId)
                        ?.code
                    )}
                  </ReactMarkdown>
                ) : 
                taskCodeStatus ==="Pending"
                ?
                <div className="loading-animation">
             
              <p style={{color:"white"}}>In the process of generating tasks.Please wait for completion.</p>
              <span className="loading-dots">
                &lt;
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
                &gt;
              </span>
            </div>
                :
                <img src={failureStage} />
                
                  // <img
                  //   src={
                  //     taskCodeStatus === "Pending"
                  //       ? emptyCodeStage
                  //       : failureStage
                  //   }
                  //   alt=""
                  // />
                }
              </p>
            </>
          )}
          {isRefineButtonClicked && !isSubmitClicked && (
            <div style={{ marginLeft: "-23%", marginRight: "1em" }}>
              <p className="horizontalLine" />
              <InputCustomComponent
                titleText={"Enter Refinement Query"}
                fileUploaded={true}
                className={"enabledInputContainer"}
                name="refine"
                onChange={(e) => setRefineInput(e.target.value)}
              />
              <div className="buttonsContainer" style={{ marginRight: "0em" }}>
                <button
                  className="greenBorderButton"
                  onClick={refineButtonClickHandler}
                >
                  Cancel
                </button>
                <button
                  className="greenButtonStyle"
                  onClick={SubmitRefinementClicked}
                  style={{
                    backgroundColor:
                      refineCodeInput.length > 0 ? "#92BB45" : "#8C8C8C",
                    color: refineCodeInput.length > 0 ? "#000" : "#FFF",
                  }}
                  disabled={refineCodeInput.length > 0 ? false : true}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          {isSubmitClicked && (
            <div
              className="toastContainer flexDirectionRow "
              style={{ marginLeft: "-25%", width: "39em" ,marginTop:"3%"}}
            >
              <img src={info} alt="" />
              <p className="toastMsg">
                Please hold on while we tailor your code to match your specific
                requirements
              </p>
            </div>
          )}

          {!isRefineButtonClicked && (
            <div
              className="flexDirectionRow"
              style={{ justifyContent: "space-between", margin: "0em 1em" }}
            >
              <div className="flexDirectionRow" style={{ gap: "1em" }}>
                {outputCode.length > 0 && (
                  <CopyToClipboard text={copyClickHandler()} onCopy={otherCopy}>
                    <Tooltip placement="top" title="Copy Code">
                      <img src={copy} alt="" />
                    </Tooltip>
                  </CopyToClipboard>
                )}
                {mainCodeClicked && driverCode.length > 0 && (
                  <Tooltip placement="top" title="Download all Main Code">
                    <img src={download} alt="" onClick={handleDownloadClick} />
                  </Tooltip>
                )}
              </div>
              {mainCodeClicked && driverCode.length > 0 && (
                <button
                  className="greenButtonStyle"
                  onClick={refineButtonClickHandler}
                >
                  Refine Code
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToaster}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Typography
          style={{
            backgroundColor: "#434343",
            color: "white",
            borderRadius: "0.25em",
            padding: "0.875em 1em",
            fontSize: "0.875em",
          }}
        >
          Copied to clipboard
        </Typography>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToasterForRefineCode}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Typography
          style={{
            backgroundColor: "#434343",
            color: "white",
            borderRadius: "0.25em",
            padding: "0.875em 1em",
            fontSize: "0.875em",
          }}
        >
          Code Refinement Successfull
        </Typography>
      </Snackbar>
    </div>
  );



};
export default GeneratedOutputWindow;
const arrowStyles = `
@keyframes arrowAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}
.animatedArrow {
  display: inline-block;
  font-size: 24px;
  margin-top: 20px;
  animation: arrowAnimation 0.8s ease-in-out infinite;
  color: #3498db; /* You can adjust the color */
}
`