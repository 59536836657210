import React, { useState,} from "react";
import { Drawer, IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {ReactComponent as ChipExpanded} from "../../images/ChipExpanded.svg";
import {ReactComponent as ArrowUp} from "../../images/ArrowUp.svg";
import ArrowDown from "../../images/ArrowDown.svg";
import {ReactComponent as DeveloperExperienceExpanded }from "../../images/DeveloperExperienceExpanded.svg";
import {ReactComponent as SDVOperateExpanded }from "../../images/SDVOperateExpanded.svg";
import FeatureManagement from "../../images/FeatureManagement.svg";
import Jira from "../../images/JIRA.svg";
import DevEnvironment from "../../images/DevEnvironment.svg";
import DevopsManager from "../../images/DevopsManager.svg";
import VirtualValidation from "../../images/VirtualValidation.svg";
import {ReactComponent as Atrifact } from "../../images/artifact.svg";
import REPO from "../../images/repo.svg";
import {ReactComponent as ActieveTabMask} from "../../images/Mask Group.svg";
import ContainerRepository from "../../images/SyntheticData.svg";
import ETAS from "../../images/CodeCoverage.svg"
import "./SideNavBar.css";
import { useNavigate,useLocation ,Link} from "react-router-dom"; // Import useNavigate here
import DShortLOgo from "../../images/D_dot.svg";
import {ReactComponent as DeloitteLogo } from "../../images/DeloitteNavLogo.svg";
import {ReactComponent  as SDVDeck} from "../../images/sdvDeck.svg";
import {ReactComponent as Logout } from "../../images/Logout.svg";
import {ReactComponent as SDVOperateACtive} from "../../images/SDVOperateActive.svg";
import {ReactComponent as IntelligentQualityActive} from "../../images/IntelligentQualityActive.svg"
import {ReactComponent as DeveloperExperienceActive} from "../../images/DeveloperExperienceActive.svg"
import {ReactComponent as ArtifactsActive} from "../../images/ArtifactsActive.svg"
import {ReactComponent as EArchitectureLogoActive} from "../../images/EArchitectureLogoActive.svg"
import {ReactComponent as SDVFrameworkLogoActive} from "../../images/SDVFrameworkLogoActive.svg"
import SOFTWAREUPDATEMANAGERLogoActive from "../../images/SOFTWAREUPDATEMANAGERLogoActive.svg"
import {ReactComponent as VideoIcon} from "../../images/VideoIcon.svg";
import EARCH from "../../images/e_arch.svg";
import SDVFramework from "../../images/sdv_framework.svg";
import SoftwareUpdateManager from "../../images/SoftwareUpdateManager.svg";
import axios from "axios";

const Sidebar = ({active,subactive}) => {

  const [open, setOpen] = useState(false);
  const [isIntegrityQualityOpen, setIsIntegrityQualityOpen] = useState(false);
  const [isDeveloperExperienceOpen, SetIsDeveloperExperienceOpen] = useState(false);
  const [isSdvOperateOpen, SetIsSdvOperateOpen] = useState(false);
  const [isDocumentsOpen, SetDocumentsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  const location=useLocation();
  const { activemenu, submenu } = location.state || {activemenu: 'ch1', submenu: ''};
  
  const handleMouseEnter = () => {
    setOpen(true); 
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const toggleChipDropdown = () => {
    setIsIntegrityQualityOpen(!isIntegrityQualityOpen);
    SetIsDeveloperExperienceOpen(false);
    SetIsSdvOperateOpen(false);
    SetDocumentsOpen(false);
  };

  const toggleDevExpDropDown = () => {
    SetIsDeveloperExperienceOpen(!isDeveloperExperienceOpen);
    SetDocumentsOpen(false);
    setIsIntegrityQualityOpen(false);
    SetIsSdvOperateOpen(false)
  };

  const toggleSdvOperateDropDown = () => {
    SetIsSdvOperateOpen(!isSdvOperateOpen);
    SetDocumentsOpen(false);
    setIsIntegrityQualityOpen(false);
    SetIsDeveloperExperienceOpen(false);
  };

  const toggleDocumentsDropDown = () => {
    SetDocumentsOpen(!isDocumentsOpen);
    setIsIntegrityQualityOpen(false);
    SetIsDeveloperExperienceOpen(false);
    SetIsSdvOperateOpen(false);
  };

  const handleIconClickIntelligentQuality = () => {
    setIsIntegrityQualityOpen(true);
    SetIsDeveloperExperienceOpen(false);
    SetIsSdvOperateOpen(false);
    SetDocumentsOpen(false);
  };

  const handleIconClickDeveloperExperience = () => {
    setIsIntegrityQualityOpen(false);
    SetIsDeveloperExperienceOpen(true);
    SetIsSdvOperateOpen(false);
    SetDocumentsOpen(false);
  };

  const handleIconClickSdvOperate = () => {
    setIsIntegrityQualityOpen(false);
    SetIsDeveloperExperienceOpen(false);
    SetIsSdvOperateOpen(true);
    SetDocumentsOpen(false);

  };

  const handleIconClickDocument = () => {
    setIsIntegrityQualityOpen(false);
    SetIsDeveloperExperienceOpen(false);
    SetIsSdvOperateOpen(false);
    SetDocumentsOpen(true);
  };
 
  const handleChapterRedirect = (e, chapter) => {

    navigate(chapter,{state:{activemenu:"ch1",submenu:""}})
  }
  const handleChapterRedirect2= (e, chapter) => {
 
    navigate(chapter,{state:{activemenu:"ch2",submenu:""}})
    }
   
  const handleChapterRedirect3 = (e, chapter) => {
 
    navigate(chapter,{state:{activemenu:"ch3",submenu:""}})
    }
   
    const handleChapterRedirectDeck = (e,chapter) => {
      
    navigate(chapter,{state:{activemenu:"deck",submenu:"eeArch"}})
   
    }

    const fetchUserData = async () => {
      try {
          
          const response = await axios.get('https://127.0.0.1:8000/logout/', {
              // headers: {
              // 'X-CSRFToken': csrfToken,
              // },
              withCredentials: true, // if you need to send cookies with the request
              });
                  console.log("Response Data",response.data)
                  
      } catch (error) {
          console.log(error.message);
      }
  };
      const handleLogout = async(e) => {
        // window.location.href ="https://127.0.0.1:8000/logout/"
        // window.location.href =`https://sdvexperience-backend.deloitte.com/sdv/logout/?username=${encodeURIComponent("Jagtap, Samruddhi Kishor")}`
        window.location.href ="https://sdvexperience-backend.deloitte.com/sdv/logout/"
        // fetchUserData()
        // navigate("/")
    
      }
const handleETASOne =() =>{
  window.open("https://625632145135.signin.aws.amazon.com/console","_blank")
}
const handleETASTwo =() =>{
  window.open("https://us-east-1.console.aws.amazon.com/systems-manager/managed-instances/rdp-connect?instances=i-0b352fe911134900e&region=us-east-1#","_blank")
}
      const handleVideoClick = (e,VideoLink) =>{
        navigate(`/persona/automotive-video-player?src=${VideoLink}`,{state:{data:VideoLink,chapter:'SDV Quality', contentTitle:"Feature Management",activemenu:"ch1",submenu:""}})
      }
      const handleSecondVideoClick = (e,VideoLink) =>{
          navigate(`/persona/automotive-video-player?src=${VideoLink}`,{state:{data:VideoLink,chapter:'SDV Hub', contentTitle:"Jira",activemenu:"ch2",submenu:""}})
        }
        const handleSdvPipelineVideoClick = (e,VideoLink) =>{
          navigate(`/persona/automotive-video-player?src=${VideoLink}`,{state:{data:VideoLink,chapter:'SDV Hub', contentTitle:"SDV Pipeline",activemenu:"ch2",submenu:""}})
        }
  return (
    <div
      className={`sidebar ${open ? "open" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
    <div className="icons">
    
      <IconButton>
        <img className="d-icon" src={DShortLOgo} alt=""/>
      </IconButton >

    {/* ChapterOne */}
      { activemenu ==="ch1"?(
        <Tooltip title="SDV Quality" placement="right" arrow>
         <div className="active-background">
       <ActieveTabMask className="bar"></ActieveTabMask>
          <IconButton
          className="icon" 
           onClick={handleIconClickIntelligentQuality}>
            <IntelligentQualityActive/>
          </IconButton>
          </div>
        </Tooltip>
      ):
      (
        <Tooltip title="Intelligent Quality" placement="right" arrow>
          <IconButton 
         className="icon"
          onClick={handleIconClickIntelligentQuality}>
           <ChipExpanded/>
          </IconButton>
        </Tooltip>
      )}
        {/* Chapter Two */}
        {activemenu ==="ch2"?(
       <Tooltip title="Developer Experience" placement="right" arrow >
        <div className="active-background">
        <ActieveTabMask className="bar"></ActieveTabMask>
          <IconButton
          className="icon" 
         onClick={handleIconClickDeveloperExperience}>
          <DeveloperExperienceActive/>
        </IconButton>
        </div>
        </Tooltip>
      ):
      (
        <Tooltip title="SDV Hub" placement="right" arrow>
        <IconButton className="icon"  onClick={handleIconClickDeveloperExperience}>
          <DeveloperExperienceExpanded/>
        </IconButton>
      </Tooltip>
      )}
       
       {/* Chapter Three */}
       {activemenu === "ch3"?(
      <Tooltip title="SDV Operate" placement="right" arrow >
             <div className="active-background">
       <ActieveTabMask className="bar"></ActieveTabMask>
          <IconButton
          className="icon" 
         onClick={handleIconClickSdvOperate}>
          <SDVOperateACtive/>
          </IconButton>
          </div>
        </Tooltip>
      ):
      (
        <Tooltip title="SDV Operate" placement="right" arrow >
        <IconButton className="icon" onClick={handleIconClickSdvOperate}>
          <SDVOperateExpanded/>
        </IconButton>
      </Tooltip>
      )}
       
       {/* Atrifacts */}
          {activemenu ==="deck"?(
     <Tooltip title="Artifacts" placement="right" arrow >
      <div className="active-deck">
  <ActieveTabMask className="bar" />
  <IconButton className="icon" onClick={handleIconClickDocument}> 
    <ArtifactsActive />
  </IconButton>
  </div>
        </Tooltip>
      ):
      (
        <Tooltip title="Artifacts" placement="right" arrow >
          <IconButton  className="icondeck" onClick={handleIconClickDocument}>
            <Atrifact/>
          </IconButton>
        </Tooltip>
      )}

      {/* Logout */}
      {activemenu ==="logout"?(
     <Tooltip title="Logout" placement="right" arrow >
      <div className="active-deck">
  <ActieveTabMask className="bar" />
  <IconButton className="icon" onClick={handleIconClickDocument}>
          <Logout></Logout>
          </IconButton>
          </div>  
        </Tooltip>
      ):
      (
        <Tooltip title="Logout" placement="right" arrow >
          <IconButton className="icon" onClick={handleIconClickDocument}>
            <Logout></Logout>
          </IconButton>
        </Tooltip>
      )}
        
      </div>
      
      <Drawer  anchor="left" open={open} onClose={() => setOpen(false)}
       ModalProps={{ // Set the ModalProps to control the modal behavior
        BackdropProps: { // Set the BackdropProps to control the properties of the backdrop overlay
          invisible: true, // Set the backdrop overlay to be invisible
        },
      }}
       >
        <div className="icons expanded">
          
          <div className="CollapsibleDropdown">

            <DeloitteLogo className="d-icon" />
          </div>
          <div className={`${activemenu==="ch1" ? 'ActiveCollapsibleDropdown' : 'CollapsibleDropdown'}`}>
            <button className={`${isIntegrityQualityOpen ? 'mainHeadingExpanded' : 'mainHeading'}`}>
            {
                activemenu==="ch1"?
                (
                <div className="sidebarItemBackground">
                <ActieveTabMask ></ActieveTabMask>
                <IntelligentQualityActive/>
                <p className="activeHeadline" >SDV Quality</p>
                </div>
                ):(
                <div  className="click-area" onClick={(e) => handleChapterRedirect(e,"/persona/chapter-one")}>
                  <ChipExpanded className="logoExpanded primaryLogo"  />
                  <p className="headLine">SDV Quality</p>
                  </div>
                  )
              }
             
              {isIntegrityQualityOpen ? (
                 <div className="expanded-area">
                <ArrowUp className="logoExpanded" onClick={toggleChipDropdown}/>
                </div>
              ) : (
                <div className="expanded-area">
                <img src={ArrowDown} alt="" className="logoExpanded"onClick={toggleChipDropdown}/>
                </div>
              )}
            </button>
            {isIntegrityQualityOpen && (
              <div className='subNavigation'>
                <div className="mainMargin">
                <div className="subNavigationDiv"><a href="https://app.launchdarkly.com/default/test/features" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={FeatureManagement} alt="" /><p className="subHeading">Feature Management</p></a>
                <VideoIcon style={{marginLeft:"10px",cursor:"pointer"}}  onClick={(e) => handleVideoClick(e, "Launch_darkley_disable.mp4")}/></div>
              </div>
              </div>
            )}
          </div>
          
          <div className={`${activemenu==="ch2" ? 'ActiveCollapsibleDropdown' : 'CollapsibleDropdown'}`}>
            <button className={`${isDeveloperExperienceOpen ? 'mainHeadingExpanded' : 'mainHeading'}`} >
            {
                activemenu==="ch2"?
                (
                  <div className="sidebarItemBackground" >
                <ActieveTabMask ></ActieveTabMask>
                <DeveloperExperienceActive/>
              <p className="activeHeadline">SDV Hub</p>
                </div>
                ):(
                  <div  className="click-area"  onClick={(e) => handleChapterRedirect2(e, "/persona/chapter-two")}>
                  <DeveloperExperienceExpanded className="logoExpanded primaryLogo"  />
              <p className="headLine" >SDV Hub</p>
                </div>)
              }
             
              {isDeveloperExperienceOpen ? (
                <ArrowUp className="logoExpanded"  onClick={toggleDevExpDropDown}/>
              ) : (
                <img src={ArrowDown} alt="" className="logoExpanded" onClick={toggleDevExpDropDown}
                />
              )}
            </button>
            {isDeveloperExperienceOpen && (
              <div className="subNavigation">
                <div className="mainMargin">
                <div className="subNavigationDiv"><a href="https://pes-deloitte.atlassian.net/jira/software/c/projects/SDV/boards/2" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={Jira} alt="" /><p className="subHeading">JIRA</p></a>
                <VideoIcon style={{marginLeft:"120px",cursor:"pointer"}}  onClick={(e) => handleSecondVideoClick(e, "JIRA TICKETS.mp4")}/></div>
                <div className="subNavigationDiv"><a href="https://deloitteaijira.atlassian.net/browse/SCRUM-1" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={Jira} alt="" /><p className="subHeading">Backlog</p></a></div>
                <div className="subNavigationDiv"><a href="https://github.dev/Deloitte-US/PES-DriverDistractionDetection_App/" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={DevEnvironment} alt=""/><p className="subHeading">Dev Environment</p></a></div>
                <div className="subNavigationDiv" onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}><img src={ETAS} alt=""/><p className="subHeading">ETAS Factory</p>
                {showOptions && (
        <div>
          <div style={{color: "#8a8a8a",fontFamily:"Open Sans",fontSize:"13px",fontWeight:"600",cursor:"pointer",marginLeft:"10px"}} onClick={handleETASOne}>AWS Account</div>
          <div style={{color: "#8a8a8a",fontFamily:"Open Sans",fontSize:"13px",fontWeight:"600",cursor:"pointer",marginLeft:"10px"}} onClick={handleETASTwo}>ETAS ENVT</div>
        </div>
      )}
                </div>

                <div className="subNavigationDiv"><a href="https://console-openshift-console.apps.sdv-pipeline.sdvhub.deloitte.com" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={DevopsManager} alt=""/><p className="subHeading">SDV Pipeline</p></a>
                <VideoIcon style={{marginLeft:"68px",cursor:"pointer"}}  onClick={(e) => handleSdvPipelineVideoClick(e, "RedHatVideo.mp4")}/></div>
                {/* <div className="subNavigationDiv"><a href="https://sdv-quay-quay-deloitte-sdv-quay.apps.multicloud-aws.rhmulticloud.com/repository/sdv/sdv?tab=tags" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={ContainerRepository} alt=""/><p className="subHeading">Container respository</p></a></div> */}

              </div>
              </div>
            )}
          </div>
            <div className={`${activemenu==="ch3"  ? 'ActiveCollapsibleDropdown' : 'CollapsibleDropdown'}`}>
            <button className={`${isIntegrityQualityOpen? 'mainHeadingExpanded' : 'mainHeading'}`}>
                {
              activemenu==="ch3"?
                (
                  <div className="sidebarItemBackground">
                <ActieveTabMask></ActieveTabMask>
                <SDVOperateACtive alt="" className="logoExpanded primaryLogo"/>
              <p className="activeHeadline" >SDV Operate</p>
                </div>):(
                  <div  className="click-area" onClick={(e) => handleChapterRedirect3(e, "/persona/chapter-three")}>
                  <SDVOperateExpanded className="logoExpanded primaryLogo"/>
              <p className="headLine">SDV Operate</p>
                </div>)
              }
              
              {isSdvOperateOpen ? (
                <ArrowUp className="logoExpanded"  onClick={toggleSdvOperateDropDown}/>
              ) : (
                <img src={ArrowDown} alt="" className="logoExpanded"
                onClick={toggleSdvOperateDropDown}
                  />
              )}
            </button>
            {isSdvOperateOpen && (
              <div className='subNavigation'>
                <div className="mainMargin">
                <div className="subNavigationDiv"><a href="https://console-openshift-console.apps.sdv-deployment-cluster.cluster.deloitte-virtual-validation.com" target="_blank" rel="noreferrer" className="subNavigationDiv link"><img src={VirtualValidation} alt="" /><p className="subHeading">Virtual Validation</p></a></div>
                <div className="subNavigationDiv">
                  <Link
                  to={"/persona/ota-details"}
                   state={ {data: 'http://telematics-frontend-1438608579.us-east-1.elb.amazonaws.com/dashboard/ota/Swift%20Drive%20X1/J7', chapter: 'SDV OPERATE', contentTitle: "Software Update Manager" ,activemenu:"ch3",submenu:"sdvOperate"} }
                   target="_self"
                  rel="noreferrer"
                  className="subNavigationDiv link"
                 >
                 
                   <img src={submenu === "sdvOperate" ? SOFTWAREUPDATEMANAGERLogoActive : SoftwareUpdateManager} alt="" />

                  <p className={`${submenu==="sdvOperate" ? 'ActivesubHeading' : 'subHeading'}`}>Software Update Manager</p>
                  </Link>
                  </div>
                  </div>
              </div>
            )}
          </div>
         
          
          <div className={`${activemenu==="deck" ? 'ActiveCollapsibleDropdownDeck' : 'CollapsibleDropdownDeck'}`} 
          style={{
             marginTop:"38vh"
            }}>
            <button className={`${isDocumentsOpen ? 'mainHeadingExpanded' : 'mainHeading'}`}>
            {
                activemenu==="deck"?
                (
                  <div className="sidebarItemBackground">
                <ActieveTabMask></ActieveTabMask>
               
                <ArtifactsActive/>
               
              <p className="activeHeadline">Artifact</p>
              </div>
                ):(
                  <div  style={{display:'flex',direction:'row',alignItems:'center'}} onClick={(e) => handleChapterRedirectDeck(e, "/persona/artifact")}>
                <Atrifact className="logoExpanded primaryLogo" />
              <p className="headLine">Artifact</p>
                </div>)
              }
             
              {isDocumentsOpen ? (
                <ArrowUp className="logoExpanded" onClick={toggleDocumentsDropDown}/>
              ) : (
                <img src={ArrowDown} alt="" className="logoExpanded"
                onClick={toggleDocumentsDropDown}
                />
              )}
            </button>
            {isDocumentsOpen && (
              <div className="subNavigation">
              <div className="mainMargin">
                <div className="subNavigationDiv">
                <Link
                  to={"/persona/artifact"}
                   state={ {activemenu:"deck",submenu:"eeArch"} }
                   target="_self"
                  rel="noreferrer"
                  className="subNavigationDiv link"
                 >
                    <img src={submenu==="eeArch" ? EArchitectureLogoActive:EARCH} alt="" />
                  <p className={`${submenu==="eeArch" ? 'ActivesubHeading' : 'subHeading'}`}>E/E Architecture</p>
                  </Link>
                  </div>

                  <div className="subNavigationDiv">
                  <Link
                  to={"/persona/artifact-sdv"}
                   state={ {activemenu:"deck",submenu:"sdvFramework"} }
                  //  target="_self"
                  rel="noreferrer"
                  className="subNavigationDiv link"
                 >
                        <img src={submenu==="sdvFramework" ? SDVFrameworkLogoActive:SDVFramework} alt="" />
                      <p className={`${submenu==="sdvFramework" ? 'ActivesubHeading' : 'subHeading'}`}>SDV Framework</p>
                      </Link></div>
                 <div className="subNavigationDiv"><a href="SDV Demo Red Hat.pdf" className="subNavigationDiv link" target="_blank" rel="noreferrer" ><SDVDeck style={{marginTop:'1.8vh'}}/><p className="subHeading">SDV Deck</p></a></div>
                 <div className="subNavigationDiv"><a href="angular_rate.pdf" className="subNavigationDiv link" target="_blank" rel="noreferrer" ><SDVDeck style={{marginTop:'1.8vh'}}/><p className="subHeading">Angular Rate Document</p></a></div>
                 <div className="subNavigationDiv"><a href="MoveAmerica_E2E_Architecture_V2.pdf" className="subNavigationDiv link" target="_blank" rel="noreferrer" ><SDVDeck style={{marginTop:'1.8vh'}}/><p className="subHeading">MoveAmerica_E2E_Architecture</p></a></div>
                 
                <div className="subNavigationDiv">
                  
            <Link
            to={"https://amedeloitte.sharepoint.com/:f:/r/sites/HIMobileandProductEngineeringServices/Shared%20Documents/General/RHSummit-2024?csf=1&web=1&e=bdXsqR"}
            state={{activemenu:"deck", submenu:"repository"}}
              className="subNavigationDiv link"
              target="_blank"
            rel="noreferrer"
            >
                  <img src={REPO} alt="" />
                  <p className={`${submenu==="repository" ? 'ActivesubHeading' : 'subHeading'}`}>Repository</p>
            </Link>
                </div> 
                </div>
              </div>
            )}
          </div>
          <div className={`${activemenu==="logout" ? 'ActiveCollapsibleDropdownDeck' : 'CollapsibleDropdownDeck'}`}>
            <button className={`${isDocumentsOpen ? 'mainHeadingExpanded' : 'mainHeading'}`} >
              <Logout className="logoExpanded primaryLogo" onClick={(e) => handleLogout()}/>
              <p className="headLine" onClick={(e) => handleLogout()}>Logout</p> 
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default Sidebar;
