import React from "react";
import { Player,ReplayControl,ControlBar,ForwardControl } from "video-react";
import "./VideoPlayer.css";
import {useNavigate,useLocation} from "react-router-dom";
import arrow from '../../images/alert_creation_arrow.png'
import Sidebar from "../SideNavBar/SideNavBar";
import { TopBarHeight} from "../../style";
const AutomotiveVideoPlayer = () => {

  const navigate = useNavigate();
  const location=useLocation();
  const { data, chapter, contentTitle } = location.state || {};

    const BackClicked = () => 
  {
    if(contentTitle==="Claim Analysis")
    {
      navigate("/claim-analysis",{state:{activemenu:"ch1",submenu:""}})
    }
  else if(chapter==="SDV Quality")
    {
       navigate("/persona/chapter-one",{state:{activemenu:"ch1",submenu:""}})
    }
  else if(chapter==="SDV Hub")
    {
       navigate("/persona/chapter-two",{state:{activemenu:"ch2",submenu:""}})
    }
    
  }


  const YourContentComponent = () => {
   
    return (
      <div id="app" style={styles.background}>
      <Sidebar />
        <div style={styles.container}>
          <div style={styles.contentContainer}>
            <div style={styles.navBar}>
            <div
              style={
                styles.sdvTitle
              }
            >
              SDV Experience
            </div>
            </div>
            <main style={styles.main}>
              <div style={styles.videoWindowGap} >
              <div style={styles.chapterTitle} onClick={BackClicked}>{chapter}</div>
        <img src={arrow} style={styles.arrowStyle} alt=""/>
        <div style={styles.videoTitle}>{contentTitle}</div>
        </div>
        <div style={styles.body.split}>
        <div style={styles.videoFrame}>
        <Player  style={styles.actualVideoLoadingFrame} src={data}>
        <ControlBar autoHide={false}>
        <ReplayControl  seconds={5} order={-1.1}/>
        <ForwardControl seconds={5} order={2.1}/>
        </ControlBar>
        </Player>
        </div>
        </div>
            </main>
          </div>
        </div>
      </div>
    );
    };

  return (
       <YourContentComponent />    
  );    
};

export default AutomotiveVideoPlayer;
const styles = {
  navBar: {
    height: TopBarHeight,
    width: "100vw",
    background: "#252525",
    display: "flex",
  },
  sdvTitle:{
    color: "#92BB45",
                fontSize: "20px",
                textTransform: "uppercase",
                fontWeight: 800,
                padding: "20px 30px 20px 40px",
                fontFamily: "Open Sans",
  },
  background: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    display:"flex",
    top: 0,
    left: 0,
    filter: "contrast(110%)",
    overflow:"hidden",
    backgroundColor: "#1A1B1C",
  },
  container: {
    display: "flex", 
  },
  contentContainer: {
   flex:1,
    marginLeft: "0vw", // Adjust the margin to accommodate the expanded sidebar
    transition: "margin-left 0.3s ease", // Add transition for smooth movement
  },
  main: {
    marginTop: "3vh",
    flex: 1, // Make the main content flex to fill the available space
    overflow: "hidden", 
  },
  videoWindowGap:{
    display: "flex",
    gap:"1vh"
  },
  arrowStyle:{
    width:"8px",height:"16px",marginTop:"4px"
  },
  videoTitle:{
    left: "11vw",color:"#86BC2A",fontSize: "16px",fontFamily: "Open Sans",fontWeight: '400'
  },
  videoFrame:{
    width:"98%"
  },
  actualVideoLoadingFrame:{
    width: "85vw",
    height: "68vh", 
   padding:"0px 20px 0px 20px",
    border: "none"
  },
  bottomRightElement :{
    position: "absolute",
    bottom: "24px",
    right: "24px", 
    alignItems:"end"
  },
  artifactTitle:{
    fontFamily: "Open Sans",
fontSize: "20px",
fontWeight: "600",
color:"white",
marginLeft: '2em',
padding:"12px 0px 0px 8px"
  },
  chapterTitle:{
    color: 'white',
    display: "flex",
    alignItems: "start",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Open Sans",
    cursor: "pointer",
    marginLeft: '2.6em'
  },
chapterInfo:{
fontFamily: "Open Sans",
fontSize: "16px",
fontWeight: 300,
position:"absolute",
color:"white",
marginLeft:"4.5vw",
marginTop:"1vh"
  },
  logo: {
    width: "8vw",
    cursor: "pointer"
  },
  home: {
    width: "3vw",
  },
  body: {
    postion: "relative",
    width: "100vw",
    height: "90vh",
    padding: "1.5vw 5vw",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    split: {
      width: "68vw",
      margin:"0 auto",
      marginTop:'1vw',
    },
  },
  card: {
    width: "48vh",
  },
  svg: { height: "inherit", width: "inherit" },
  svgLink: { height: "inherit", width: "inherit", cursor: "pointer" },
  tab: {
    height: "37vh",
    position: "absolute",
    left: "0",
  },
  label: {
    color: "white",
    marginRight: "10px", // Space between label and input
    width: "100px", // Adjust width based on your label size
  },
  inputField: {
    flex: 1,
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
  },
  button:{
    fontSize: '25px', 
    justifyContent: 'center',  
    color: 'white', // Set the text color to white
    backgroundColor: '#de8500', // Set the background color as an example
    // Add other button styles as needed
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease', // Smooth transition over 0.3s
    '&:hover': {
      backgroundColor: 'black', // Change background color on hover
    },
  
  },

};