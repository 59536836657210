import React,{useState} from "react";
import arrow from '../../images/alert_creation_arrow.png'
import Sidebar from "../../components/SideNavBar/SideNavBar";
import BackTitle from "../../components/BackTitle/BackSubTitle";
import ChatBot from "../../templates/ChatBot";
import {ReactComponent as ChatBotLogo} from "../../images/ChatBotButton.svg";
import "./Ontology.css"
import TopBar from "../../components/TopBar/TopBar";

const Ontology = () => {
 
    const [isChatBotOpen, setChatBotOpen] = useState(false);
  
    const HnadleChatBotClicked = async(e) => {
      setChatBotOpen(true);    
    };
    const handleClosePopup = () => {
      setChatBotOpen(false);
  };
    const YourContentComponent = () => {
      return (
        <div id="app" className="background">
           <Sidebar />
          <div className="container">
            <div className="contentContainer">
              {/* <div className="navBar">
              <div
               className="SDVTitle">
                SDV Experience
              </div>
              </div> */}
              <TopBar></TopBar>
              <main className="main">
                <div className="mainWindowGap">
         <BackTitle link={"/persona/chapter-two"} title="SDV Hub" menu="ch2" submenuName=""/>
       
          <img src={arrow} className="arrowStyle" alt=""/>
          <div className="subTitle">Requiremt Tracebility</div>
          </div>
          <div className="ontologyLoader" >
                         <iframe
                         title="OntologyDetailsPage"
                           src={"https://dp8bxyeczqirv.cloudfront.net/ontology_2/index.html"}
                          className="ontologyIframe"
                         />
                 </div>
                 <ChatBotLogo className="bottomRightElement" onClick={HnadleChatBotClicked}></ChatBotLogo> 
              </main>
            </div>
          </div>
          {isChatBotOpen && (
        <div className="chatBotLogo">
          <ChatBot onCloseClick={handleClosePopup} />
        </div>
      )}
        </div>
      );
              };
              return (
               
                  <YourContentComponent />
                   
              );

};

export default Ontology;
