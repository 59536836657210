import React, { useEffect, useState, useRef } from "react";
import "./GenerateCode.css";
import "./Dots.css";
import InputCustomComponent from "../../../../components/CustomInput/InputCustomComponent";
import TextAreaCutomComponent from "../../../../components/CustomInput/TextAreaCutomComponent";
import emptyCodeStage from "../../../../images/emptyCodeStage.svg";
import { ReactComponent as EmptyCodeStage1 } from "../../../../images/emptyCodeStage.svg"
import failureStage from "../../../../images/API_fail_state.svg"
import { Style } from "@mui/icons-material";
const GenerateTask = ({ addTaskClick, taskList, title, description,setTaskData,deleteTask, apiStatus, retryHandler }) => {

  const taskEndRef = useRef(null);

  const onChangeNewTaskTextAreaInput = (e) => {
    description(e.target.value);
  };

  const onChangeTitleTextInput = (e) => {
    title(e.target.value);
  };


  useEffect(() => {
    setTaskData(taskList);
  }, [taskList]);

  const handleDeleteTask = (taskId) => {
    deleteTask(taskId)
  };
  const reTryClicked = ()=>{
    retryHandler()
  }

  const handleTextAreaChange = (id, newValue) => {
    setTaskData((prevTaskData) =>
      prevTaskData.map((item) =>
        item.id === id ? { ...item, description: newValue } : item
      )
    );
  };

  useEffect(() => {
    if (addTaskClick) {
      scrollToBottom();
    }
  }, [addTaskClick]);

  const scrollToBottom = () => {
    if (taskEndRef.current) {
      taskEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    
    
    <div className="generateTaskContaier">
      <p className="requirementSummaryText">Generate Task</p>
      {apiStatus=== "Failure" && <div className="emptyStateContaier" style={{display:"flex",flexDirection:"Column", gap:"24px"}}>
            <img src={failureStage} alt="" />
            <button className="greenButtonStyle" onClick={reTryClicked}>Retry</button>
          </div>}
          <div style={{overflowY:"auto"}}>
      {taskList?.length>0?taskList?.map((item, index) => {
        return (
          <TextAreaCutomComponent
            onChange={(event) =>
              handleTextAreaChange(item.id, event.target.value)
            }
            id={item.id}
            Title={item.title}
            value={item.description}
            onDelete={() => handleDeleteTask(item.id)}
            deleteVisibility={true}
          />
        );
      }):(
        apiStatus!== "Failure"&&
        // <div className="emptyStateContaier">
          <div style={{width:"50vw",display: "flex",alignItems:"center",justifyContent:"center",marginTop:"10vh"}}>

            {/* <img src={emptyCodeStage} alt="" /> */}
            <div className="loading-animation">
             
              <p style={{color:"white"}}>In the process of generating tasks.Please wait for completion.</p>
              <span className="loading-dots">
                &lt;
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
                &gt;
              </span>
            </div>
          </div>
        )}
        </div>
      
      {addTaskClick && (
        <div ref={taskEndRef}>
          <p
            className="horizontalLine"
            style={{ width: "49.375em"}}
          />
          <p className="requirementSummaryText" style={{background:"transparent"}}>Enter Task Details</p>
          <InputCustomComponent
            onChange={onChangeTitleTextInput}
            titleText={"Task Title"}
            className="enabledInputContainer"
            fileUploaded={true}
          />
          <TextAreaCutomComponent
            onChange={onChangeNewTaskTextAreaInput}
            Title={"Task Details"}
            deleteVisibility={false}
          />
        </div>
      )}
    </div>
  
  );
};
export default GenerateTask;
