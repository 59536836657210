import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import "./topbar.css";
import Cookies from 'js-cookie';
const TopBar = () => {

    const navigate = useNavigate();
   
    //sso
    const [userDetails, setUserDetails] = useState(null);
    const [userName, setUserName] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [error, setError] = useState(null);
  
    useEffect(() => {
  
      const csrfToken = Cookies.get('csrftoken');
      const sessionId = Cookies.get('sessionid');
      console.log("cookies",Cookies)
      console.log("token",csrfToken)
      console.log("session",sessionId)
      const fetchUserData = async () => {
          try {
              
              const response = await axios.get(
                //  'https://127.0.0.1:8000/getName/',
                 'https://sdvexperience-backend.deloitte.com/sdv/getName/',
                // 'https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/sdv/getName/', 
                {
                  headers: {
                  'X-CSRFToken': csrfToken,
                  },
                  withCredentials: true, // if you need to send cookies with the request
                  });
                      console.log("Response Data",response.data['username'])
                      setUserDetails(response.data);
                      setUserName(response.data['username'])
  
          } catch (error) {
            // window.location.href ="https://Telematics-Backend-895771443./us-east-1.elb.amazonaws.com/login/azuread-oauth2/"
            window.location.href ="https://sdvexperience-backend.deloitte.com/login/azuread-oauth2/";
            // navigate("/")
             setError(error.message);
             console.error('There was an error fetching the profile name:', error);
            
          }
      };
  
      const fetchProfilePhoto = async () => {
        try {
            const response = await axios.get(
              'https://sdvexperience-backend.deloitte.com/sdv/getProfilePhoto/',
              // 'https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/sdv/getProfilePhoto/',
               {
                responseType: 'blob', // Important for getting the image as a blob
                withCredentials: true, // if your backend requires authentication cookies
            });
  
            const url = URL.createObjectURL(response.data);
            setPhotoUrl(url);
        } catch (error) {
            setError('Failed to load profile photo');
            console.error('There was an error fetching the profile photo:', error);
        }
    };


    function getAuthStatus() {
      fetch('https://sdvexperience-backend.deloitte.com/sdv/authStatus/', {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
      })
      .then(response => response.json())
      .then(data => {
          if (data.is_authenticated) {
              // User is authenticated; navigate to the desired page
              console.log("AUTH is DOne")
              fetchUserData()
              fetchProfilePhoto()
              // window.location.href = '/dashboard/';
          } else {
              // User is not authenticated; redirect to the login page
              
              console.log("AUTH is required")
              window.location.href='https://sdvexperience-backend.deloitte.com/login/azuread-oauth2/'
          }
      })
      .catch(error => {
          console.error('Error fetching authentication status:', error);
      });
  }
   
    // if (csrfToken === null || csrfToken === undefined){
    // window.location.href ="https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/login/azuread-oauth2/"
    // fetchProfilePhoto();
    // fetchUserData();
    // }
    // else{
    //   fetchProfilePhoto();
    //   fetchUserData();
    // }
    
    // if (!userName){
    // console.log("Username is none")
    // window.location.href ="https://Telematics-Backend-895771443.us-east-1.elb.amazonaws.com/login/azuread-oauth2/"
    // fetchProfilePhoto();
    // fetchUserData();
    // }
    // else{
    // console.log("Username is not none")
    //   fetchProfilePhoto();
    //   fetchUserData();
    // }


    //without checking token
    // fetchUserData();
    // fetchProfilePhoto();
    getAuthStatus();
    
    },[]);

    return(
        <div className="navBar">
              <div className="SDVMainTitle">
                SDV Experience
              </div>
              {photoUrl ? (
               <img src={photoUrl} alt="Profile" style={{ width: '60px', height: '60px', borderRadius: '50%',position: 'absolute', right: '0', padding: '10px' }} />
           ) : (
               <p></p>
           )}
              {userName &&  <div style={{color:'white',position: 'absolute', right: '50px', padding: '10px'}}>{userName}</div>}
            </div>
      
    );
}
export default TopBar;