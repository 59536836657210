import React, { useEffect, useState } from "react";
import "./MctReport.css";
import axios from "axios";

const MctReport = ({ vinId = 0 }) => {
  const [logsList, setLogsList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const DummyLogsData = [
    { Vin: "1KGEJ6605D0F11787", Ecu: "TI J7" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:09:01.837706"},
    { Vin: "1KGEJ6605D0F11787", Ecu: "TI J7" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:09:15.653739"},
    { Vin: "1KGEJ6605D0F11787", Ecu: "TI J7" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:09:01.037449"},
    { Vin: "1KGEJ6605D0F11787", Ecu: "TI J7" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:08:01.037912"},
    { Vin: "1KGEJ6605D0F11787", Ecu: "TDA4VM" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:08:01.037675"},
    { Vin: "1KGEJ6605D0F11787", Ecu: "TDA4VM" ,Application:"DMS	",Message:"ALERT_MESSAGE",Timestamp:"2024-01-03 16:08:01.027754"}

  ];

  useEffect(() => {
    getMCTLogs();
  }, []);

  const getMCTLogs = async () => {
    setIsDataLoading(true);
    let claimListURL =
      // "https://telematics-backend-895771443.us-east-1.elb.amazonaws.com/sdv/getDDLogs/";
      "https://sdvexperience-backend.deloitte.com/sdv/getDDLogs/"
    if (!isDataLoading)
      await axios
        .post(claimListURL, {
          vin: vinId,
        })
        .then((result) => {
          setIsDataLoading(false);
          setLogsList(result.data);
        })
        .catch((error) => {
          setIsDataLoading(false);
        });
  };

  return (
    <div className="mct-report-wrapper">
      <div className="mct-report-title">Generate Report</div>
      <div className="mct-report-table-wrapper">
        <table className="mct-report-custom-table">
          <thead>
            <tr>
              <th>VIN</th>
              <th>ECU Name</th>
              <th>Application</th>
              <th>Message</th>
              <th>Time Stamp</th>
            </tr>
          </thead>
{isDataLoading === true ? (
            <thead className="mct-report-table-body">
            {DummyLogsData.map((entry, index) => (
              <tr key={index} className="mct-report-table-row">
                <td>{entry.Vin}</td>
                <td>{entry.Ecu}</td>
                <td>{entry.Application}</td>
                <td>{entry.Message}</td>
                <td>{entry.Timestamp}</td>
              </tr>
            ))}
            </thead>
        ):
        (
          <thead>
            {logsList.map((entry, index) => (
          <tr key={index} className="mct-report-table-row">
            <td>{entry.Vin}</td>
            <td>{index > 14 ? 'TDA4VM' : entry["Ecu name"]}</td>
            <td>{entry.Application}</td>
            <td>{entry.Message}</td>
            <td>{entry.Timestamp}</td>
          </tr>
        ))}
          </thead>
        )      
      }
        </table>
      </div>
    </div>
  );
};
export default MctReport;
