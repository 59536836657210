import React, { useState } from "react";
import "./SummaryCard.css"

const SummaryCard = ({
  logo,
summaryTitle,
summarySubTitle,
paddingValue,
 
}) => {

  const [hovered, setHovered] = useState(false);
  return (
    <>
        <div
        className="summaryCardMain"
        >
          <div className="title">{summaryTitle}</div>
          <div className="logo">
          <img src={logo} style={{paddingTop:paddingValue}}alt=""></img>
          </div>
          <div className="graph-summary">
   
   <img src={summarySubTitle} style={{}}alt=""></img>
   </div>
        </div>
    
    </>
  );
};

export default SummaryCard;
