import React, { useEffect, useState } from "react";
import "./SdvOperateDashboard.css";
import {
  Dialog,
  IconButton,
} from "@mui/material";
import Sidebar from "../../components/SideNavBar/SideNavBar";
import ValidationStatusSuccess from "../../images/validation-status-success.png";
import ValidationStatusFailure from "../../images/validation-status-failure.png";
import axios from "axios";
import expandVideoIcon from "../../images/expand_video_icon.png";
import CloseIcon from "@mui/icons-material/Close";
import ChatBot from "../../templates/ChatBotOperate.js";
import { ReactComponent as ChatBotLogo } from "../../images/ChatBotButton.svg";
import { useNavigate } from "react-router-dom";
import alert from "../../images/alert.png";
import TopBar from "../../components/TopBar/TopBar.js";

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;

}

const SDVOperateDashboard = () => {
  const [otaSummary, setOtaSummary] = useState(undefined);
  const [validationSummaryData, setValidationSummaryData] = useState(0);
  const [openIframePopup, setOpenIframePopup] = useState(false);
  const [isChatBotOpen, setChatBotOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getOtaSummary();
    const interval = setInterval(() => {
      setValidationSummaryData(getRandomNumber(1.3, 50.18).toFixed(2));
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup function to clear the interval
  }, []);
  
  const getOtaSummary = async () => {
    let classifiedClaimURL =
      "https://sdvexperience-backend.deloitte.com/fota/otaSummary/";
    await axios
      .post(classifiedClaimURL, { model:"Swift Drive X1",hardware:"J7" })

      .then((otaSummary) => {
        const result = otaSummary.data;
        setOtaSummary(result);
      })
      .catch((error) => {});
  };

  const handleOpenPopup = () => {
    navigate("/persona/ota-details", {state: {data:'https://sdvplm.deloitte.com/dashboard/ota/Swift%20Drive%20X1/J7', chapter: 'SDV OPERATE', contentTitle: "Software Update Manager" ,activemenu:"ch3",submenu:"sdvOperate"} })
  };

  const handleClosePopup = () => {
    setOpenIframePopup(false);
  };

  const handleChatBotClose = () => {
    setChatBotOpen(false);
  };

  const HnadleChatBotClicked = async (e) => {
    setChatBotOpen(true);
  };
  return (
    <div className="sdv-operate-dashboard-wrapper">
      <div className="sidebar-wrapper">
        <Sidebar></Sidebar>
      </div>
      <div className="right-content">
        {/* <div className="top-header">
          <div className="sdv-experience-text">SDV Experience</div>
        </div> */}
        <TopBar></TopBar>
        <div className="main-content">
          <div style={{ height: "85px" }}>
            <div className="sdv-operate-title">SDV Operate</div>
            <div className="sdv-operate-subtitle">
            Service offering and capability that helps to scale software delivery across parameters with processes and OTA platform models.
            </div>
          </div>
          <div class="sdv-operate-content-wrapper">
            <div class="software-update-manager-wrapper">
              <div class="software-update-title">
                <div>Software Update Manager</div>
                <div>
                  <img
                    src={expandVideoIcon}
                    alt="expandVideoIcon"
                    onClick={handleOpenPopup}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <iframe
                class="software-update-manager"
                title="sdv-operate-iframe"
                src={
                  // "https://sdvplm.deloitte.com/dashboard/ota/Swift%20Drive%20X1/TDA4VM"
                  "https://sdvplm.deloitte.com/dashboard/ota/Swift%20Drive%20X1/J7"
                  // "http://telematics-frontend-1438608579.us-east-1.elb.amazonaws.com/dashboard/ota/Swift%20Drive%20X1/TDA4VM"
                }
              />
            </div>

            <div className="ota-summary-wrapper grid-item">
              <div className="ota-summary-title-wrapper">
              <img src={alert} alt="validation-status"/>
                <div className="ota-summary-title">Mandatory OTA Software Update </div>
              </div>
              <div className="ota-summary-details-wrapper">
              <div className="ota-summary-details">
                  <div className="ota-summary-details-key">Seuqence of VIN's in scope:</div>
                  <div className="ota-summary-details-value">
                    {otaSummary?.vins && otaSummary.vins.length > 0 && otaSummary.vins.slice(0, Math.min(4, otaSummary.vins.length)).join(',')}
                  </div>
                </div>
                <hr />

                <div className="ota-summary-details">
                  <div className="ota-summary-details-key">
                    {/* Last software update date: */}
                    Release Date:
                  </div>
                  <div className="ota-summary-details-value">
                    {otaSummary?.release_date
                      ? new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(new Date(otaSummary?.release_date))
                      : ""}
                  </div>
                </div>
                <hr />
                <div className="ota-summary-details">
                  <div className="ota-summary-details-key">
                   Software Update Count:
                  </div>
                  <div className="ota-summary-details-value">
                    {otaSummary?.needs_update_count}
                  </div>
                </div>
                <hr />
                <div className="ota-summary-details">
                  <div className="ota-summary-details-key">Latest Version:</div>
                  <div className="ota-summary-details-value">
                    {otaSummary?.latest_version}
                  </div>
                </div>
                <hr />
                <div className="ota-summary-details">
                  <div className="ota-summary-details-key">
                    Software package summary:
                  </div>
                  <div className="ota-summary-details-value">
                    {otaSummary?.summary}
                  </div>
                </div>
              </div>
            </div>
            <div className="validation-summary-wrapper grid-item">
              <div class="validation-summary-title">VALIDATION SUMMARY</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "24px",
                  gap: "16px",
                }}
              >
                <div className="validation-status-wrapper">
                  <img
                    src={
                      validationSummaryData < 20
                        ? ValidationStatusSuccess
                        : ValidationStatusFailure
                    }
                    styles={{ height: "68px", width: "68px" }}
                    alt="validation-status"
                  />
                  <div style={{ textAlign: "center" }}>Validation Status</div>
                </div>
                <a href="virtual_validation.json" className="vulnerability-score" target="_blank" rel="noreferrer" >
                  <div className="bar-overflow">
                    <div
                      className="bar"
                      style={{
                        transform: `rotate(${
                          45 +
                          
                          ((validationSummaryData || 0) /
                            100) *
                            180
                        }deg)`,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "relative",
                        top: "25px",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "#D3F261",
                      }}
                    >
                      {validationSummaryData || 0}
                    </div>
                  </div>
                  <div style={{ textAlign: "center",textDecoration:"none",color:"white" }}>
                    {" "}
                    Vulnerability Score
                  </div>
               </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openIframePopup}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="70vw"
        maxHeight="70vh"
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 999,
            }}
          >
            <CloseIcon sx={{ backgroundColor: "rgba(230, 247, 255, 0.2)" }} />
          </IconButton>
          <div className="IframeBackground">
            <iframe
              className="Iframe"
              title="sdv-operate-iframe"
              src={
                // "https://sdvplm.deloitte.com/dashboard/ota/Swift%20Drive%20X1/TDA4VM"
                "https://sdvplm.deloitte.com/dashboard/ota/Swift%20Drive%20X1/J7"
                // "http://telematics-frontend-1438608579.us-east-1.elb.amazonaws.com/dashboard/ota/Swift%20Drive%20X1/TDA4VM"
              }
            />{" "}
          </div>
        </div>
      </Dialog>
      <ChatBotLogo
        className="chatBotLogo"
        onClick={HnadleChatBotClicked}
      ></ChatBotLogo>
       {isChatBotOpen && (
        <div className="chatBotWindow">
          <ChatBot onCloseClick={handleChatBotClose} />
        </div>
      )}
    </div>
  );
};

export default SDVOperateDashboard;
