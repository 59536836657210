import React from "react";
import "./InputCustomComponent.css";

const InputCustomComponent = ({
  titleText,
  placeholder,
  name,
  fileUploaded,
  value,
  onChange,
  className
}) => {
  return (
    <div className="inputFiledContainer">
      <p className="inputHeadingText" style={{marginTop:"-8px"}}>{titleText}</p>
      <input 
      style={{cursor:fileUploaded?"":"not-allowed"}}
        name={name}
        className={className}
        value={value}
        placeholder={placeholder}
        type="Text"
        disabled={fileUploaded ? false : true}
        onChange={onChange}
      ></input>
    </div>
  );
};
export default InputCustomComponent;
