import React, { useEffect, useState } from "react";
import "./claimAnalysis.css"; // Import CSS file for styling
import { TopBarHeight } from "../../style";
import navbarIcon1 from "../../images/claim-analysis-navbar-icon-1.png";
import navbarIcon2 from "../../images/claim-analysis-navbar-icon-2.png";
import navbarIcon3 from "../../images/claim-analysis-navbar-icon-3.png";
import navbarIcon5 from "../../images/claim-analysis-navbar-icon-5.png";
// import navbarIcon6 from "../../images/serviceNow.png";
// import navbarIcon6 from "../../images/ServiceNowSmallLogo.png";
import navbarIcon6 from "../../images/S_Logo.png";
import expandVideoIcon from "../../images/expand_video_icon.png";
import arrow from "../../images/alert_creation_arrow.png";
import Popup from "../../templates/Popup";
import axios from "axios";
import { ControlBar, ForwardControl, Player, ReplayControl } from "video-react";
import { useNavigate ,useLocation} from "react-router-dom";
import { Dialog, IconButton, MenuItem, Select } from "@mui/material";
import Sidebar from "../../components/SideNavBar/SideNavBar";
import MctReport from "../../templates/MctReport";
import CloseIcon from "@mui/icons-material/Close";
import ChatBot from "../../templates/ChatBotClaimAnalysis";
import { ReactComponent as ChatBotLogo } from "../../images/ChatBotButton.svg";
import { ReactComponent as Palantir } from "../../images/Palantir.svg";
import TopBar from "../../components/TopBar/TopBar";

const ClaimAnalysis = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(undefined);
  const [selectedClaimId, setSelectedClaimId] = useState(undefined);
  const [claimListData, setClaimListData] = useState([]);
  const [classifiedClaims, setClassifiedClaims] = useState([]);
  const [hoveredNav, setHoveredNav] = useState(-1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2023);
  const [selectedGraph, setSelectedGraph] = useState("");
  const navigate = useNavigate();
  const [isChatBotOpen, setChatBotOpen] = useState(false);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const location=useLocation();
  const { incidentNumber } = location.state || {};
  console.log("IncidentNumber===============",incidentNumber)
  const [isSnackbarVisible, setSnackbarVisible] = useState(true);

   // Handler to close or hide the Snackbar
   const handleCloseSnackbar = () => {
    setSnackbarVisible(false);
  };

  const redirectServiceNowIncident = () => {
    
    const url=`https://deloitteus11.service-now.com/wm_order_list.do?sysparm_query=number%3D${incidentNumber}`
    window.open(url, '_blank');
    
  };

  const navbarIconDetails = [
    {
      icon: navbarIcon6,
      tooltipMessage: "Service Now",
      // link: "https://deloitteus14.service-now.com",
      link:`https://deloitteus11.service-now.com/wm_order_list.do?sysparm_query=number%3D${incidentNumber}`,
      helpVideoLink: "",
    },
    {
      icon: navbarIcon1,
      tooltipMessage: "Vulnerability Ananlysis and Intrusion Detection",
      link: "https://portal-stg.atom.trendmicro.com/#/signin",
      helpVideoLink: "VicOne.mp4",
    },
    {
      icon: navbarIcon2,
      tooltipMessage: "Run Time Behaviour Analysis ",
      link: "http://aurora-demo-test.s3-website.eu-central-1.amazonaws.com/#/login",
      helpVideoLink: "Aurora_lab.mp4",
    },
    // {
    //   icon: navbarIcon3,
    //   tooltipMessage: "Real time Diagnostics and Analytics",
    //   link: "https://docsend.com/view/hmz9vkp75zejdj7t",
    //   helpVideoLink: "",
    // },
    {
      icon: navbarIcon5,
      tooltipMessage: "Telemetry Analytics",
      link: "http://telematics-frontend-1438608579.us-east-1.elb.amazonaws.com/dashboard/report",
      helpVideoLink: "",
    },
    
  ];

  useEffect(() => {
    getClassifiedClaims();
  }, []);

  const getClassifiedClaims = async () => {
    let classifiedClaimURL =
      "https://dp8bxyeczqirv.cloudfront.net/classified_claims.json";
    await axios
      .get(classifiedClaimURL)
      .then((data) => {
        const result = data.data;
        setClassifiedClaims(result);
        if (result?.length > 0) getClaimList(result[0].name, result);
      })
      .catch((error) => {});
  };

  const getClaimList = async (component, claims = classifiedClaims) => {
    setSelectedComponent(component);
    const result =
      claims.find((componentDetails) => componentDetails.name === component)
        ?.claims || [];

    setClaimListData(result);

    if (result?.length > 0) getVehicleData(result[0]);
  };

  const getVehicleData = async (selectedClaim) => {
    setSelectedClaimId(selectedClaim.id);
    setVehicleDetails(selectedClaim);

    setSelectedGraph("");
    if (selectedClaim?.eventTimeLineGraphs?.length) {
      setSelectedYear(
        selectedClaim?.eventTimeLineGraphs[
          selectedClaim?.eventTimeLineGraphs.length - 1
        ].year
      );
      setSelectedGraph(
        selectedClaim?.eventTimeLineGraphs[
          selectedClaim?.eventTimeLineGraphs.length - 1
        ].graphLink
      );
    }
  };

  const styles = {
    navBar: {
      height: TopBarHeight,
    },
  };

  const handleYesClick = async () => {
    // Handle the "Yes" response here
    setIsPopupOpen(false); // Close the popup
    let jiraCreationUrl =
      "https://sdvexperience-backend.deloitte.com/sdv/createJira/";
    let requestBody = {
      "vin": "1KGEJ6605D0F11787",
    };
    //API Calling
    await axios
      .post(jiraCreationUrl, requestBody)
      .then((result) => {
      })
      .catch((error) => {});
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleChatBotClose = () => {
    setChatBotOpen(false);
  };

  const escalateComponentProblemClick = async () => {
    setIsPopupOpen(true);
    
  };

  const onBackButtonClick = () => {

    navigate("/persona/chapter-one", {
      state: { activemenu: "ch1", submenu: "" },
    });
  };

  const setHoverNavIndex = (index) => {
    setHoveredNav(index);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedGraph(
      vehicleDetails?.eventTimeLineGraphs?.find(
        (graph) => graph.year === event.target.value
      )?.graphLink
    );
  };

  const clickIcon = (iconDetails) => {
    if (iconDetails.tooltipMessage === "Telemetry Analytics")
      setModalOpen(true);
    else window.open(iconDetails.link, "_blank");
  };

  const HnadleChatBotClicked = async (e) => {
    setChatBotOpen(true);
  };
 

  const handleOpenVideoPopup = () => {
    setOpenVideoPopup(true);
  };

  const handleVideoPopupClose = () => {
    setOpenVideoPopup(false);
  };

  const clickHelp = (iconDetails) => {
    navigate(`/persona/automotive-video-player?src=${iconDetails.helpVideoLink}`,{state:{data:iconDetails.helpVideoLink,chapter:'SDV Quality', contentTitle:"Claim Analysis",activemenu:"ch1",submenu:""}})

  };

  return (
    <div className="claim-summary-details-wrapper">
      <div className="sidebar-wrapper">
        <Sidebar></Sidebar>
      </div>
      <div className="right-content">
        {/* <div style={styles.navBar} className="top-header">
          <div className="sdv-experience-text">SDV Experience</div>
        </div> */}
        <TopBar></TopBar>
        {incidentNumber && isSnackbarVisible &&(
        <div className="snackbar">{incidentNumber} Incident created in ServiceNow
         <div style={{ marginTop: '10px' }}>
            <button onClick={redirectServiceNowIncident}>Click Here</button>
            <button onClick={handleCloseSnackbar} style={{ marginLeft: '10px' }}>Close</button>
          </div>
        </div>

      )}
        <div className="main-content">
          <div className="sub-header">
            <div className="sdv-quality-bread-crumb">
              <div style={{ display: "flex", gap: "1vh" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                   
                    navigate(
                      "/persona/chapter-one",
                      {state:{ activemenu: "ch1", submenu: "" } }
                    )
                  }
                >
                  SDV Quality
                </div>
                <img src={arrow} alt="bread-crumb-icon" />
                <div
                  style={{
                    color: "#86BC2A",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                  }}
                >
                  Claim Analysis 
                 
                </div>
              </div>
            </div>
            <div className="claim-analysis-navbar-wrapper">
              <div style={{display:"flex",flexDirection:"row",gap:"7px"}}>
                <div>Claim Analysis </div>
                <Palantir></Palantir>
                 </div>

              <div className="claim-analysis-navbar">
                {navbarIconDetails.map((iconDetails, index) => (
                  <div
                    style={{
                      display: "flex",
                      "align-items": "center",
                      gap: "12px",
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => setHoverNavIndex(index)}
                    onMouseLeave={() => setHoverNavIndex(-1)}
                    onClick={() => {
                      if (index >= 2) clickIcon(iconDetails);
                    }}
                  >
                    <img
                      src={iconDetails.icon}
                      className="claim-analysis-navbar-icons"
                      alt="claim-analysis-navbar-icons"
                    ></img>
                    {hoveredNav === index &&
                      (index < 2 ? (
                        <div
                          style={{
                            width: "160px",
                            fontSize: "10px",
                            lineHeight: "12px",
                            weight: "600",
                            display: "flex",
                            flexDirection: "column",
                            background: "#252525",
                            padding: "8px",
                            cursor: "pointer",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            onClick={() => clickIcon(iconDetails)}
                            className="navbar-icon-click-options"
                          >
                            {iconDetails.tooltipMessage}
                          </div>
                          {index < 2 && (
                            <div
                              className="navbar-icon-click-options"
                              onClick={() => clickHelp(iconDetails)}
                            >
                              {"Need Help"}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "160px",
                            fontSize: "10px",
                            lineHeight: "12px",
                            weight: "600",
                          }}
                        >
                          {iconDetails.tooltipMessage}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="claim-summary-wrapper">
            <div className="claim-summary-left">
              <div className="claim-list-wrapper">
                <div className="classified-claims">
                  <div className="classified-claims-title">
                    Classified Claims
                  </div>
                  <div className="classified-claims-table">
                    <div className="classified-claims-table-header">
                      <div className="classified-claims-table-first-column">
                        ID
                      </div>
                      <div className="classified-claims-table-second-column">
                        Component
                      </div>
                    </div>
                    <div className="classified-claims-table-rows">
                      {classifiedClaims.map((claim, index) => (
                        <div
                          key={index}
                          class={
                            "classified-claims-table-row " +
                            (selectedComponent === claim.name
                              ? "selected-row"
                              : "")
                          }
                          onClick={() => getClaimList(claim.name)}
                        >
                          <div className="classified-claims-table-first-column">
                            {claim.id}...
                          </div>
                          <div className="classified-claims-table-second-column">
                            {claim.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {claimListData?.length > 0 && (
                  <div className="claim-list">
                    <div className="claim-list-title">
                      <div>Claim List</div>
                      <div className="selected-component">
                        {selectedComponent}
                      </div>
                    </div>
                    <div className="claim-list-table">
                      <div className="claim-list-table-header">
                        <div className="claim-list-table-first-column">
                          VIN ID
                        </div>
                        <div className="claim-list-table-second-column">
                          Model
                        </div>
                      </div>
                      <div className="claim-list-table-rows">
                        {claimListData.map((claim, index) => (
                          <div
                            key={index}
                            class={
                              "claim-list-table-row " +
                              (selectedClaimId === claim.id
                                ? "selected-row"
                                : "")
                            }
                            onClick={() => getVehicleData(claim)}
                          >
                            <div className="claim-list-table-first-column">
                              {claim.vin}
                            </div>
                            <div className="claim-list-table-second-column">
                              {claim.model}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="claim-summary-right">
              <div className="vehicle-health-wrapper">
                <div className="vehicle-health-title">
                  Vehicle health & diagnostics
                </div>
                <div className="vehicle-health-and-diagnostics">
                  <div class="vinId">VIN ID = {vehicleDetails.vin}</div>
                  <div class="vehicle-health-data-wrapper">
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key"> 1. Model :</div>
                      <div class="vehicle-health-data-value">
                        {vehicleDetails.model}
                      </div>
                    </div>
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key"> 2. Component :</div>
                      <div class="vehicle-health-data-value">
                        <div class="vehicle-health-data-component-details-wrapper">
                          <div className="vehicle-health-data-component-details">
                            <div className="vehicle-health-data-component-key">
                              Hardware Version:
                            </div>
                            <div>{vehicleDetails.hw_ver}</div>
                          </div>
                          <div className="vehicle-health-data-component-details">
                            <div className="vehicle-health-data-component-key">
                              Software Version:
                            </div>
                            <div>{vehicleDetails.sw_ver}</div>
                          </div>
                          <div className="vehicle-health-data-component-details">
                            <div className="vehicle-health-data-component-key">
                              Supplier Name:
                            </div>
                            <div>{vehicleDetails.supplier}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key">
                        {" "}
                        3. Dealers comments:
                      </div>
                      <div class="vehicle-health-data-value">
                        {vehicleDetails.comment}
                      </div>
                    </div>
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key">
                        {" "}
                        4. Event time :
                      </div>
                      <div class="vehicle-health-data-value">
                        {vehicleDetails.event_date}
                      </div>
                    </div>
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key"> 5. Mfg date:</div>
                      <div class="vehicle-health-data-value">
                        {vehicleDetails.mfgyear}
                      </div>
                    </div>
                    <div class="vehicle-health-data">
                      <div class="vehicle-health-data-key"> 6. Region</div>
                      <div class="vehicle-health-data-value">
                        {vehicleDetails.region}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-details-wrapper">
                <div className="view-details-heading">
                  <div>
                    View events details :-
                    {vehicleDetails.id}
                  </div>
                  <div>
                    {vehicleDetails?.video_link && (
                      <img
                        src={expandVideoIcon}
                        alt="expandVideoIcon"
                        onClick={handleOpenVideoPopup}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "min-height": "100px",
                  }}
                >
                  {vehicleDetails.video_link ? (
                    <Player
                      src={vehicleDetails.video_link}
                      style={{ height: "300px", paddingTop: 0 }}
                    >
                      <ControlBar autoHide={false}>
                        <ReplayControl seconds={5} order={-1.1} />
                        <ForwardControl seconds={5} order={2.1} />
                      </ControlBar>
                    </Player>
                  ) : (
                    <div>Video currently not available</div>
                  )}
                </div>
              </div>
              <div className="events-timeline-graph-wrapper">
                <div className="events-timeline-graph-heading">
                  <div>Events Timeline Graph</div>
                  <div>
                    {vehicleDetails?.eventTimeLineGraphs?.map(
                      (timelineGraphDetails) => {
                        <div>{timelineGraphDetails.year}</div>;
                      }
                    )}
                    <Select
                      labelId="select-label"
                      value={selectedYear}
                      label="Year"
                      className="events-timeline-graph-dropdown"
                      onChange={onYearChange}
                    >
                      {vehicleDetails?.eventTimeLineGraphs?.map(
                        (timelineGraphDetails, index) => (
                          <MenuItem value={timelineGraphDetails.year}>
                            {timelineGraphDetails.year}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                </div>
                <div
                  style={{
                    minHeight: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedGraph ? (
                    <img
                      className="events-timeline-graph"
                      src={selectedGraph}
                      alt="events-timeline-graph"
                    />
                  ) : (
                    <div>Timeline Graph is not available</div>
                  )}
                </div>
              </div>
              <div className="vehicle-knowledge-graph-wrapper">
                <div className="vehicle-knowledge-graph-heading">
                  {/* Vehicle knowledge Graph */}
                  Claim-to-component knowledge Graph
                </div>
                <div
                  style={{
                    minHeight: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <iframe
                    className="vehicle-knowledge-graph"
                    title="vehicle-knowledge-graph-iframe"
                    src={
                      " https://dp8bxyeczqirv.cloudfront.net/ontology_1/index.html"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <div
            class="escalate-component-problem-button"
            onClick={escalateComponentProblemClick}
          >
            Escalate Component Problem
          </div>
          <div class="back-button" onClick={onBackButtonClick}>
            Back
          </div>

        </div>
        {isPopupOpen && (
          <div
            style={{
              position: "fixed",
              bottom: "24px",
              right: "24px",
              zIndex: 9999,
            }}
          >
            <Popup
              vin={vehicleDetails.vin}
              issue={vehicleDetails.comment}
              onNoClick={handleClosePopup}
              
            />
          </div>
        )}
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="70vw"
        maxHeight="70vh"
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 999,
            }}
          >
            <CloseIcon sx={{ backgroundColor: "rgba(230, 247, 255, 0.2)" }} />
          </IconButton>
          <MctReport vinId={vehicleDetails.vin}></MctReport>
        </div>
      </Dialog>

      <Dialog
        open={openVideoPopup}
        onClose={handleVideoPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="70vw"
        maxHeight="70vh"
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleVideoPopupClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 999,
            }}
          >
            <CloseIcon sx={{ backgroundColor: "rgba(230, 247, 255, 0.2)" }} />
          </IconButton>
          <div style={{ background: "black", width: "60vw" }}>
            <Player
              src={vehicleDetails.video_link}
              style={{ height: "300px", paddingTop: 0 }}
            >
              <ControlBar autoHide={false}>
                <ReplayControl seconds={5} order={-1.1} />
                <ForwardControl seconds={5} order={2.1} />
              </ControlBar>
            </Player>{" "}
          </div>
        </div>
      </Dialog>
      <ChatBotLogo
        style={{
          position: "absolute",
          bottom: "24px",
          right: "24px",
          alignItems: "end",
          cursor:"pointer"
        }}
        onClick={HnadleChatBotClicked}
      ></ChatBotLogo>
       {isChatBotOpen && (
        <div style={{ position: "fixed", bottom: "24px", right: "24px", zIndex: 9999 }}>
          <ChatBot onCloseClick={handleChatBotClose} />
        </div>
      )}
    </div>
  );
};

export default ClaimAnalysis;
