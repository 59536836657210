import React, { useEffect, useState } from "react";
import "./SpectToCodeDashboard.css";
import "../../../components/CustomInput/InputCustomComponent.css";
import uploadDocument from "../../../images/uploadDocument.svg";
import Dotted_Line from "../../../images/Dotted_Line.svg";
import attach from "../../../images/attach.png";
import InputCustomComponent from "../../../components/CustomInput/InputCustomComponent";
import GenerateCodePopupContainer from "./GenerateCodePopup/GenerateCodePopupContainer";
import {ReactComponent as VideoIcon} from "../../../images/VideoIcon.svg";
import { useNavigate,useLocation ,Link} from "react-router-dom"; // Import useNavigate here
import axios from "axios";
import { Input } from "antd";
import { Opacity } from "@mui/icons-material";
const { TextArea } = Input;


const SpectToCodeDashboard = () => {
  const fileTypes = ["txt"];
  const [fileUploaded, setFileUploaded] = useState(null);
  const [isCodeButtonClicked, setIsCodeButtonClicked] = useState(false);
  const [chipNameInput, setChipNameInput] = useState("");
  const [sensorNameInput, setsensorNameInput] = useState("");
  const [requirementInput, setRequirementInput] = useState("");
  const [enableGenerateBtn, setEnableGenerateBtn] = useState(false);
  const [taskResponse, setTaskResponse] = useState([]);

  
const navigate = useNavigate();
const location=useLocation();
  const handleFileUploadedChange = (event) => {
    //single file upload
    setFileUploaded(event.target.files[0]);
    console.log("file data",event.target.files[0]);
  };

  const handleFileNameClick = (event) =>{
    const filePath = './angular_config_file.txt'; // Local path to the text file
    createFileObject(filePath)
  .then(file => {
    console.log('File object created:', file);
    setFileUploaded(file)
    setChipNameInput("LSM6DSOX")
    setsensorNameInput("Angular rate")
    setRequirementInput("Write a driver code that can interact with an Angular rate sensor The code should include read/write interface definitions,buffer that will store the data being read, and an interface status.Implement the following functionalities \n"+
    
    "1. Initialize the IMU sensor\n"+
    "2. Retrieving raw data from the angular rate sensor\n"+
    "3. Perform required angular rate conversion.")
 
    // Now you can use the file object as needed
  });
  };

  // Assuming you have a text file named "example.txt" in the same directory as your JavaScript file

const createFileObject = (filePath) => {
  return fetch(filePath) // Fetch the file from the local path
    .then(response => response.blob()) // Convert the response to a blob
    .then(blob => new File([blob], 'angular_config_file.txt', { type: 'text/plain' })) // Create a File object
    .catch(error => {
      console.error('Error fetching the file:', error);
    });
};
  const popupCloseHandler = (e) => {
    setIsCodeButtonClicked(e);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "chipName":
        setChipNameInput(value);
        break;
      case "sensorName":
        setsensorNameInput(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {

    if (
      chipNameInput.length > 0 &&
      sensorNameInput.length > 0 &&
      requirementInput.length > 0
    ) {
      setEnableGenerateBtn(true);
    } else {
      setEnableGenerateBtn(false);
    }
  }, [
    sensorNameInput.length,
    requirementInput,
    chipNameInput,
    enableGenerateBtn,
  ]);
  const generateBtnClickHandler = () => {
    // enableGenerateBtn && generateTasks();
    enableGenerateBtn && setIsCodeButtonClicked(!isCodeButtonClicked);
  };

  const handleSecondVideoClick = (e,VideoLink) =>{
       
    navigate(`/persona/automotive-video-player?src=${VideoLink}`,{state:{data:VideoLink,chapter:'SDV Hub', contentTitle:"Embedded Code Generation",activemenu:"ch2",submenu:""}})
  }
  return (
    <div className="mainFrameContainer">
      
      <div className="mainFrameHeading mainFrameHeadingContainer" style={{ display: "flex", alignItems: "center" }}>
        Embedded Code Generation
  <VideoIcon style={{ marginLeft: "auto" ,cursor:"pointer"}} onClick={(e) => handleSecondVideoClick(e, "SpecToCode_Video.mp4")}/>
      </div>
      <div style={{ display: "flex" }}>
        <div className="uploadDocumentContaier">
          <div className="uploadDocumentTitle">
            Upload Sensor Configuration Document
          </div>
          <div className="clickToUploadContainer">
            <img style={{ marginTop: "1.5em" }} src={uploadDocument} alt="" />
            <div className="clickTouploadTextContainer">
           
              <p className="clickTouploadText">
                Click or drag file to this area to upload
              </p>
              <p
                className="clickTouploadText"
                style={{ fontSize: "0.875em", marginTop: "-0.625em" }}
              >
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
              <input
                type="file"
                onChange={handleFileUploadedChange}
                accept=".txt"
                disabled={isCodeButtonClicked?true:false}
              />
            </div>
          </div>

          {fileUploaded && (
            <div style={{ paddingTop: "0.5em" }}>
              <img src={attach} alt="attach" />
              <span className="uploadedFileText">{`${fileUploaded.name}`}</span>
            </div>
          )}:(
            <div style={{ paddingTop: "0.0em" }}></div>
            <Link
                      style={{
                        alignItems: "end",
                        textDecoration: "none",
                        color: "white",
                        fontSize:"14px",fontFamily:"Open Sans",fontWeight:"400"
                      }}
                      to={
                        "https://www.ti.com/lit/ug/spruj62/spruj62.pdf?ts=1714913529791"
                      }
                      target="_blank"
                    >
                      TDA4 EVM User's Guide
                    </Link>
            <div style={{marginTop:"0.2em" }}>
          <Link
                      style={{
                        alignItems: "end",
                        textDecoration: "none",
                        color: "white",
                        fontSize:"14px",fontFamily:"Open Sans",fontWeight:"400"
                      }}
                      to={
                        "https://www.ti.com/lit/ug/spruj70a/spruj70a.pdf?ts=1714648506800&ref_url=https%253A%252F%252Fwww.ti.com%252Ftool%252FSK-AM69"
                      }
                      target="_blank"
                    >
                      AM69 Processor Starter Kit
                    </Link>
                  
                   

           <p className="uploadedStaticFileText"onClick={handleFileNameClick}>
       Click here to upload file: angular_config_file.txt
      </p>
          </div>
          )
        
        </div>
      
        <img style={{ marginTop: "0.3375em" }} src={Dotted_Line} alt="" />
        <div className="sensorConfig">
          <div className="uploadDocumentTitle">Sensor Configuration Input</div>
          <InputCustomComponent
            titleText={"Enter the chip name"}
            placeholder={"example"}
            name="chipName"
            fileUploaded={isCodeButtonClicked
?
isCodeButtonClicked
:
fileUploaded
}
            value={chipNameInput}
            onChange={handleInputChange}
            className={
              fileUploaded ? "enabledInputContainer" : "inputContainer"
            }
          />
          <InputCustomComponent
            titleText={"Enter the Sensor name"}
            placeholder={"example"}
            name="sensorName"
            fileUploaded
            ={
            isCodeButtonClicked
            ?
            isCodeButtonClicked
            :
            fileUploaded
            }
            value={sensorNameInput}
            onChange={handleInputChange}
            className={
              fileUploaded ? "enabledInputContainer" : "inputContainer"
            }
          />
          <p className="inputHeadingText marginTop">Enter the driver code requirement</p>
          <TextArea
            onChange={(e) => setRequirementInput(e.target.value)}
            placeholder="Autosize height based on content lines"
            value={requirementInput}
            style={{
              height: "5.25em",
              resize: "none",
              border: fileUploaded ? "0.0625em solid #8C8C8C" : "0.0625em solid #8C8C8C",
              background: fileUploaded ? "#141414" : "#595959",
              color: "white",
             opacity: isCodeButtonClicked?0:1
            }}

          />
        </div>
        <button
          disabled={enableGenerateBtn ? false : true}
          className="codeButtonStyle"
          style={{
            backgroundColor: enableGenerateBtn ? "#92BB45" : "#8C8C8C",
            marginTop: "1.25em",
            color: enableGenerateBtn ? "#000" : "#FFF",
          }}
          onClick={(e) => {
            generateBtnClickHandler();
          }}
        >
          Initiate Code Generation
        </button>
      </div>
      {isCodeButtonClicked && (
        <GenerateCodePopupContainer 
          popupCloseHandler={popupCloseHandler}
          codePopupVisibility={isCodeButtonClicked}
          file={fileUploaded}
          chipName={chipNameInput}
          sensorName={sensorNameInput}
          requirementCode={requirementInput}
          onInputChange={handleInputChange}
        />
      )}
    </div>
  );
};
export default SpectToCodeDashboard;
