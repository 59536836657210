import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/SideNavBar/SideNavBar";
import "./Chapterone.css";
import ChatBot from "../../templates/ChatBotHub";
import { ReactComponent as ChatBotLogo } from "../../images/ChatBotButton.svg";
import { ReactComponent as ProfileIcon } from "../../images/profile_logo.svg";
import { ReactComponent as CountIcon } from "../../images/badge.svg";
import axios from "axios";
import SpectToCodeDashboard from "../Spec_To_Code/Dashboard/SpectToCodeDashboard";
import TopBar from "../../components/TopBar/TopBar";

const ChapterTwo = () => {

  const [isChatBotOpen, setChatBotOpen] = useState(false);
  const handleClosePopup = () => {
    setChatBotOpen(false);
  };

  const JiraIssue = [
    { title: "Issue 1", Issue: "Camera Module not functioning , operating in glitchy mode " ,version:"1.1",storyPoints:"2",epic:"DMS"},
    { title: "Issue 2", Issue: "Multiple popups of driver being distracted on the Infotainment screen." ,version:"2.1",storyPoints:"3",epic:"DMS"},
    { title: "Issue 3", Issue: "Navigation system is not working" ,version:"1.2",storyPoints:"4",epic:"Navigation Application"},
    { title: "Issue 4", Issue: "Lidar Sensor detection issue " ,version:"1.3",storyPoints:"2",epic:"Lidar"},
  ];

  const HnadleChatBotClicked = async (e) => {
    let jiraCreationUrl =
      "https://sdvexperience-backend.deloitte.com/sdv/upload_pdf/";
    let requestBody = {
      question: "inputText",
    };
    //API Calling
    await axios
      .post(jiraCreationUrl, requestBody)
      .then((result) => {
        setChatBotOpen(true);
      })
      .catch((error) => {});
  };

  const YourContentComponent = () => {
    
    return (
      <div id="app" className="background">
        <Sidebar />
        <div className="container">
          <div className="contentContainer">
           
              <TopBar></TopBar>
             
            <main className="main">
              {/* Your webpage content here */}
              <div className="chapterTitle">SDV Hub</div>
              <div style={{ display: "flex" }}>
                <div className="chapterInfo">
                Demand-oriented virtualization & validation supported by generative AI for quality assurance, product owners and developers.
                </div>
              </div>
              <div
                className="contentGap"
              >
                <div className="jiraBackground">
                  <div
                   className="jiraWidget"
                  >
                    <div className="jiraTitle">Issue List</div>
                    <Link
                      className="jiraViewButton"
                      to={
                        "https://pes-deloitte.atlassian.net/jira/software/c/projects/SDV/boards/2"
                      }
                      target="_blank"
                    >
                      View
                    </Link>
                  </div>
                  <div>
                    
          {JiraIssue.length > 0 ? (
         JiraIssue.map((issue,index) => (
              <div style={{ color: "white" ,padding:"5px 13px 0px 13px"}}>
                      <div
                     className="jiraListItem"
                      >
                        <div
                         className="jiraIssueTitle"
                        >
                          {issue.title}
                        </div>
                        <div
                         className="jiraissueDesc"
                        >
                          {issue.Issue}
                        </div>
                        <div>
                          <div
                           className="jiraIssueVersion"
                          >
                            {issue.version}
                          </div>
                        </div>
                        <div style={{ width: "210px" ,paddingLeft:"10px"}}>
                          <div
                           className="jiraEpic"
                           >
                            {/* Hardware Modification */}
                          {issue.epic}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "18px" }}>
                          <ProfileIcon></ProfileIcon>
                        </div>
                        <div style={{padding: "10px" }}>
                          <CountIcon></CountIcon>
                        
                        </div>                       
                      </div>  
                    </div>
         )
         ))
    :(<></>)}
                  </div>
   
                </div>
                <div className="ontology">
                  <div
                   className="ontologyBackground">
                    <div className="ontologyTitle">
                      {/* Requirement Tracebility */}
                      Full requirements traceability from spec to SBOM and hardware component
                    </div>
                    <Link
                     className="ontologyViewButton"
                      to={"/persona/ontology"}
                      state={ {chapter: 'SDV Hub', contentTitle: "Requiremt Traceability" ,activemenu:"ch2",submenu:""} }
                    >
                      View
                    </Link>
                  </div>
                  <div style={{ padding:"10px"}}>
                    
                      <iframe
                      title="ontology"
                      src={"https://dp8bxyeczqirv.cloudfront.net/ontology_2/index.html"}
                     className="ontologyPreiewIframe"
                    ></iframe>
                  </div>
                </div>     
              </div>
              <SpectToCodeDashboard />
              <ChatBotLogo className="chatBotLogo" onClick={HnadleChatBotClicked}></ChatBotLogo> 
    
            </main>
          </div>
        </div>

        {isChatBotOpen && (
        <div className="chatBotWindow">
          <ChatBot onCloseClick={handleClosePopup} />
        </div>
      )}
      </div>
    );
  };
  return (
      <YourContentComponent/>
  );
};

export default ChapterTwo;

