import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createHashRouter, RouterProvider } from "react-router-dom";
import AutomotiveVideoPlayer from "./components/VideoPlayer/AutomotiveVideoPlayer";
import LoginContextProvider from "./store/LoginContextProvider";
import OtaDetails from "./pages/ota-details/OtaDetails";
import ChapterOne from "./pages/dashboard/ChapterOne";
import ChapterTwo from "./pages/dashboard/ChapterTwo";
import Sidebar from "./components/SideNavBar/SideNavBar";
import DocumentLoaderWindow from "./pages/artifacts/DocumentLoaderWindow";
import DocumentLoaderWindowSDV from "./pages/artifacts/DocumentLoaderWindowSDV";
import ClaimAnalysis from "./pages/claim-analysis/ClaimAnalysis";
import SDVOperateDashboard from "./pages/dashboard/SdvOperateDashboard";
import ProtectedRoute from "./pages/ProtectedRoute";
import Ontology from "./pages/ontology/Ontology";
// export const pathNames = [
//   "/persona/video-player",
//   "/persona/chapter-one",
//   "/persona/web-page",
// ];
// export const pathTitles = {
//   "/persona/chapter-one/issue-creation": "Issue Creation",
//   "/persona/web-page": "Web Page",
// };
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  { path: "persona/chapter-one", element: <ChapterOne /> },
  { path: "persona/chapter-two", element: <ChapterTwo /> },
      { path: "persona/chapter-three", element: <SDVOperateDashboard /> },
      { path: "persona/automotive-video-player",element: <AutomotiveVideoPlayer />},  
      { path: "persona/artifact",element: <DocumentLoaderWindow/>},
      {
        path: "persona/artifact-sdv",
        element: <DocumentLoaderWindowSDV />,
      },
      {
        path: "persona/ota-details",
        element: <OtaDetails />,
      },
      {
        path:"persona/ontology",
        element:<Ontology/>
      },
      {
        path: "navbar",
        element: <Sidebar />,
      },
      {
        path: "claim-analysis",
        element: <ClaimAnalysis />,
      },
  {
    element: <ProtectedRoute />,
    children: [
     
      { path: "persona/chapter-one", element: <ChapterOne /> },
      { path: "persona/chapter-two", element: <ChapterTwo /> },
      { path: "persona/chapter-three", element: <SDVOperateDashboard /> },
      { path: "persona/automotive-video-player",element: <AutomotiveVideoPlayer />},  
      { path: "persona/artifact",element: <DocumentLoaderWindow/>},
      {
        path: "persona/artifact-sdv",
        element: <DocumentLoaderWindowSDV />,
      },
      {
        path: "persona/ota-details",
        element: <OtaDetails />,
      },
      {
        path:"persona/ontology",
        element:<Ontology/>
      },
      {
        path: "navbar",
        element: <Sidebar />,
      },
      {
        path: "claim-analysis",
        element: <ClaimAnalysis />,
      },
      {
        path: "*/*",
        redirect: "/",
      },
    ],
  },
]);
const domain = "dev-kfsxic63qu1dlvsz.us.auth0.com";
const clientId = "RyHUp3yXX34kk1xF7BddGsKuOxepcoQU";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoginContextProvider>
      <RouterProvider router={router}/>
    </LoginContextProvider>
  </React.StrictMode>
);
reportWebVitals("Report Data",console.log);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();